@charset "UTF-8";

.pop-up-validation {
    display: none;
    position: absolute;
    background-color: #222;
    box-shadow: 0 0 3px 1px #818181;
    color: #dbdbdb;
    border-radius: 5px;
    z-index: 1000;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 0.8rem;
}

.pop-up-validation p {
    max-width: 360px;
    margin: 0;
}

.pop-up-validation .pointer {
    position: absolute;
    width: 0;
    height: 0;
    bottom: -10px;
    left: calc(50% - 10px);
    transform: rotate(180deg);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #222;
}

@media screen and (max-width: 580px) {
    .pop-up-validation p {
        max-width: 250px;
        margin: 0;
    }
}
