/* ----- font-style : normal ----- */

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Thin"), local("NeueHaasUnica-Thin"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Thin.woff2")
            format("woff2");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Extra Light"), local("NeueHaasUnica-ExtraLight"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtraLight.woff2")
            format("woff2");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Light"), local("NeueHaasUnica-Light"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Light.woff2")
            format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Regular"), local("NeueHaasUnica-Regular"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Regular.woff2")
            format("woff2");
    font-weight: normal; /* 400 */
    font-style: normal;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Medium"), local("NeueHaasUnica-Medium"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Medium.woff2")
            format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Bold"), local("NeueHaasUnica-Bold"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Bold.woff2")
            format("woff2");
    font-weight: bold; /* 600 */
    font-style: normal;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Extra Bold"), local("NeueHaasUnica-ExtraBold"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtraBold.woff2")
            format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Black"), local("NeueHaasUnica-Black"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Black.woff2")
            format("woff2");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Extra Black"), local("NeueHaasUnica-ExtraBlack"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtraBlack.woff2")
            format("woff2");
    font-weight: 900;
    font-style: normal;
}

/* ----- font-style : italic ----- */

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Thin Italic"), local("NeueHaasUnica-ThinItalic"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ThinItalic.woff2")
            format("woff2");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica ExtLt Ita"), local("NeueHaasUnica-ExtLtIta"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtLtIta.woff2")
            format("woff2");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Light Italic"),
        local("NeueHaasUnica-LightItalic"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-LightItalic.woff2")
            format("woff2");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Italic"), local("NeueHaasUnica-Italic"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Italic.woff2")
            format("woff2");
    font-weight: normal; /* 400 */
    font-style: italic;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Medium Italic"),
        local("NeueHaasUnica-MediumItalic"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-MediumItalic.woff2")
            format("woff2");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Bold Italic"), local("NeueHaasUnica-BoldItalic"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-BoldItalic.woff2")
            format("woff2");
    font-weight: bold; /* 600 */
    font-style: italic;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica Bold Italic"), local("NeueHaasUnica-BoldItalic"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-BoldItalic_1.woff2")
            format("woff2");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica ExtBd Ita"),
        local("NeueHaasUnica-ExtraBoldItalic"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtraBoldItalic.woff2")
            format("woff2");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Neue Haas Unica";
    src: local("Neue Haas Unica ExtBlk Ita"), local("NeueHaasUnica-ExtBlkIta"),
        url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtBlkIta.woff2")
            format("woff2");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: Montserrat;
    src: local("Montserrat Light"), local("Montserrat-Light"),
        url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-Light.ttf")
            format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-Regular.ttf")
            format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: local("Montserrat Medium"), local("Montserrat-Medium"),
        url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-Medium.ttf")
            format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
        url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-SemiBold.ttf")
            format("truetype");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: local("Montserrat Bold"), local("Montserrat-Bold"),
        url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-Bold.ttf")
            format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: DMSans;
    src: local("DMSans Regular"), local("DMSans-Regular"),
        url("https://resources.ocumetra.com/fonts/dm_sans/DMSans-Regular.ttf")
            format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: DMSans;
    src: local("DMSans Medium"), local("DMSans-Medium"),
        url("https://resources.ocumetra.com/fonts/dm_sans/DMSans-Medium.ttf")
            format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: DMSans;
    src: local("DMSans Bold"), local("DMSans-Bold"),
        url("https://resources.ocumetra.com/fonts/dm_sans/DMSans-Bold.ttf")
            format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}
