@charset "UTF-8";
@use "sass:map";
@use "ui_kit/theme/colors.scss" as *;
@use "ui_kit/theme/mixins" as *;
@use "ui_kit/theme/breakpoints.scss" as *;

@import "libs/fonts";

$grid-breakpoints: (
    xs: 0,
    xxs: 390px,
    sm: 640px,
    md: 768px,
    lg: 1025px,
    xl: 1280px,
    lxl: 1400px,
    xlx: 1512px,
    xxl: 1920px,
);
$container-max-widths: $grid-breakpoints;
$enable-negative-margins: true;

// config
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";

$utilities: map.merge(
    $utilities,
    (
        "font-weight":
            map.merge(
                map.get($utilities, "font-weight"),
                (
                    responsive: true,
                )
            ),
    )
);

//  layout and components
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/helpers";
@import "node_modules/bootstrap/scss/utilities/api";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/popover";
/* stylelint-disable */
$flagsImagePath: "../node_modules/intl-tel-input/build/img/";
/* stylelint-enable */
@import "node_modules/intl-tel-input/src/css/intlTelInput";
@import "libs/input_validation";

:root {
    --input-padding-x: 1.5rem;
    --input-padding-y: 0.75rem;
    --navbar-height: 80px;
    --color-background: #060f24;
    --color-btn: #8acbb7;
    --color-btn-hover: #7dfed7;
}

body {
    position: relative;
    margin: 0;
    min-height: 100vh;
    background: var(--color-background);
    background-size: cover;
    color: #fff;
    font-family: Montserrat, Arial, sans-serif !important;
    font-style: normal;
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
}

#background-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--color-background);
    overflow: hidden;
}

#background-container .img-background {
    width: 660px;
    height: 990px;
}

#base-background {
    position: absolute;
    right: 0;
    background: url("https://resources.ocumetra.com/images/backgrounds/base.webp")
        no-repeat;
    mix-blend-mode: lighten;
    background-position-y: 50%;
    background-size: 1100px !important;
}

#all-content {
    width: 100%;
    min-height: calc(100vh - var(--navbar-height));
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 70px;
}

#print-help-results {
    display: none;
}

.meye-logo-title {
    font-family: "Bradley Hand", sans-serif;
    font-size: 50px;
    margin: 10px 0;
    color: white;
}

.meye-logo-title span {
    font-family: "Neue Haas Unica", sans-serif;
    font-weight: 500;
}

.welcome-container,
.error-container {
    width: 100%;
    margin: 0 100px;
}

.welcome-container h1,
.error-container h1 {
    font-size: 80px;
    font-weight: 300;
}

.error-container h2 {
    font-size: 40px;
    font-weight: 300;
}

.connect-button-container {
    width: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.form-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
}

.form-btn {
    text-decoration: none;
    font-weight: 300;
    color: var(--color-background);
    border: 2px solid var(--color-btn);
    background-color: var(--color-btn);
    font-size: 22px;
    padding: 10px 40px;
    border-radius: 7px;
    cursor: pointer;
    transition: 0.2s;
}

.form-btn:hover {
    text-decoration: none;
    color: var(--color-background);
    background-color: var(--color-btn-hover);
}

.form-btn:disabled {
    opacity: 0.8;
    background-color: transparent;
    border-color: var(--color-background);
    cursor: default;
}

#nav {
    min-height: var(--navbar-height);
    font-weight: 500;
    padding: 10px 60px;
}

#logo-navbar {
    display: block;
    content: url("../images/logos/ocumetra_logo_light.svg");
    background-repeat: no-repeat;
    width: 160px;
}

li {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &.li-margin {
        margin-left: 1.5rem;
    }

    &.bullet {
        list-style-type: disc;
    }
}

table {
    border-spacing: 10px;
    border-collapse: separate;
    width: 80%;
}

.navbar-dark .navbar-toggler {
    border-color: rgb(255 255 255);
}

.navbar-toggler .line {
    background-color: rgb(255 255 255);
    border-radius: 10px;
    width: 22px;
    height: 2px;
    margin: 6px;
    transition: 0.5s;
}

.active #line1 {
    transform: rotate(-45deg) translate(-6px, 6px);
}

.active #line2 {
    opacity: 0;
}

.active #line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}

/*  login and sign-up styling */

.profile {
    width: 100%;
}

.card-profile {
    border: 0;
    width: 600px;
    min-width: 450px;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    color: #28283d;
    padding-left: 15px;
    padding-right: 15px;
}

.blue-eye-color {
    color: #171792;
}

.card-base {
    width: 50%;
    min-width: 300px;
    max-width: 500px;
    border-radius: 1rem;
    background-color: rgb(255 255 255 / 20%);
    color: #28283d;
    padding: 2rem;
    backdrop-filter: blur(50px);
}

.card-base .card-title {
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    color: white;
    margin-bottom: 2rem;
}

.card-register {
    max-width: 900px;
}

.form-base {
    width: 100%;
}

.form-group-row {
    display: flex;
    justify-content: space-between;
    grid-gap: 0 10px;
}

.form-group-row > div {
    width: 100%;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group select {
    appearance: none;
}

.form-label-group input,
.form-label-group select {
    height: 48px;
    border-radius: 14px;
    border: none;
    background-color: white;
}

// To Overwrite User Agent Input Autofill
/* stylelint-disable */
input:-internal-autofill-selected {
    @include dark {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0 1000px $dark-blue-400 inset !important;
        background-color: $dark-blue-400 !important;
    }
}

input,
select {
    @include dark {
        color-scheme: dark;
    }
}

/* stylelint-enable */

.form-label-group > input,
.form-label-group > label,
.form-label-group > select {
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
}

.show-hide-password-btn {
    position: relative;
    z-index: 100;
    display: inline-block;
    width: 30px;
    height: 25px;
    object-fit: contain;
    align-self: center;
    cursor: pointer;
}

.show-hide-password-btn.show {
    background: transparent
        url("https://resources.ocumetra.com/images/icons/eye-hide-icon.svg") 50%
        no-repeat;
}

.show-hide-password-btn.hide {
    background: transparent
        url("https://resources.ocumetra.com/images/icons/eye-show-icon.svg") 50%
        no-repeat;
}

.formgroup input::placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown),
.form-label-group select {
    font-size: 18px;
    width: 100%;
    padding-top: calc(
        var(--input-padding-y) + var(--input-padding-y) * (2 / 3)
    );
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #28283d;
}

.card-link-container {
    display: flex;
    justify-content: space-between;
    color: white;
    gap: 0 10px;

    .checkbox-control {
        display: flex;
        align-items: baseline;
        gap: 4px;
    }
}

.card-link-container a,
.card-link-container a:hover {
    color: white;
    text-decoration: none;
}

.card-link-container .custom-control-label a {
    color: #8acbb7;
}

.custom-control-label {
    cursor: pointer;
}

.iti {
    width: 100%;
}

/* stylelint-disable */
.iti__selected-flag {
    padding: 0;
}

.iti--allow-dropdown input[type="tel"] {
    padding: 0;
}

.iti__country-list {
    background-color: transparent;
    backdrop-filter: blur(20px);
    z-index: 100;
}

/* stylelint-enable */

.nav-link.active {
    color: #7dfed7 !important;
}

.navbar .form-select-sm {
    display: flex;
    align-items: center;
    position: relative;
    color: #a3a3a3;
    padding-left: 0;

    & > select,
    ul {
        cursor: pointer;
        background-color: transparent;
        font-size: 1rem;
        font-weight: 500;
        border: none;
        color: inherit;
        text-align: right;

        &:hover {
            color: #e5e5e5;
        }
    }

    &::after {
        content: "";
        position: relative;
        left: -16px;
        border: 0.3em solid transparent;
        border-top-color: currentcolor;
        border-bottom: 0;
        margin-left: 0.255em;
        display: block;
    }
}

.dropdown-btn {
    color: rgba(255 255 255 / 55%);
    font-weight: 500;
}

.dropdown-menu {
    background-color: rgb(255 255 255 / 20%) !important;
    backdrop-filter: blur(50px);
    border: 0 !important;
    color: #fff !important;
}

.dropdown-menu a:link,
.dropdown-menu a:visited,
.dropdown-menu a:active {
    color: #fff;
}

a {
    text-decoration: none;
}

.dropdown-menu a:hover,
.dropdown-menu a:focus {
    color: rgb(39 39 39);
    text-decoration: none;
}

.dropdown-item {
    color: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--color-background) !important;
    color: #fff !important;
}

.links-container {
    display: flex;
    margin-left: 50px;
}

.links-container ul {
    margin: 0 80px 0 0;
    padding: 0;
}

.links-container a {
    color: #fff;
}

.links-container a:hover {
    text-decoration: none;
    color: #d0d2d3;
}

.links-container span,
.source-container a:link,
.source-container a:visited {
    color: #8acbb7;
}

.copyright-container {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 50px;
}

/* ------------------------- Modal / Popup ------------------------- */

.modal-title {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-background);
    font-weight: bold;
    text-decoration: underline;
}

.header-style {
    font-size: calc(1.375rem + 0.8vw);
    font-weight: 500;
}

.modal-body {
    color: var(--color-background);
}

.terms-title {
    font-size: 24px;
    font-family: "Segoe UI Semibold", sans-serif;
    text-align: justify;
}

.modal-body li {
    list-style-type: disc;
}

.modal-body .decimal li {
    list-style-type: decimal;
}

.modal-body ol li {
    list-style-type: lower-alpha;
}

.modal-body h2 {
    text-align: center;
    font-size: 24px;
    text-decoration: underline;
    margin-bottom: 1rem;
    font-weight: normal;
    font-family: "Segoe UI", sans-serif;
}

.modal-body h3 {
    font-size: 20px;
    margin-bottom: 1rem;
}

.modal-body h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.modal-text-body {
    line-height: 115%;
    text-align: justify;
}

/* ------------------------- Subscription Succes  ------------------------- */

.subscription-success {
    width: 100%;
}

.card-success-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 85%;
    max-width: 1200px;
    margin: auto;
    color: #28283d;
    backdrop-filter: blur(50px);
    background-color: rgb(255 255 255 / 20%);
    border-radius: 1rem;
    padding: 2rem;
}

.img-doctor-success {
    display: block;
    content: url("https://resources.ocumetra.com/images/pictures/doctor-succes.png");
    background-repeat: no-repeat;
    width: 12em;
}

.text-success-container {
    color: white;
    width: 40%;
}

.text-success-container h2 {
    margin-bottom: 20px;
}

.text-success-container p {
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 18px;
}

.text-success-container a {
    color: var(--color-btn);
    font-weight: 500;
}

.floating-label {
    background-color: #8acbb7;
    margin-top: -20px;
    color: #060f24;
    line-height: 16px;
    margin-right: 10px;
}

.brand-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;

    .brand-item {
        &.source-item {
            order: 2;
            text-align: center;
        }

        &.logo-item {
            &:nth-of-type(2) {
                order: 1;
            }

            &:nth-of-type(3) {
                order: 3;
            }
        }
    }
}

.focus-alert {
    border: none;
    outline: none;
}

.coopervision-logo-macro {
    p {
        margin-bottom: 0;
        text-align: right;
        color: var(--color-btn);
    }

    img {
        max-width: 183px;
        height: auto;
    }
}

.coopervision-logo-break-margin {
    margin-top: 30px;
}

.ocumetra-logo-macro {
    max-width: 246px;

    p {
        margin-bottom: 0;
        margin-left: 70px;
        font-size: 12px;
        color: white;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.ad-card-control {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 5px lightgrey;

    .ad-list {
        padding: 0;
        height: 100%;
        max-width: 350px;
        margin-bottom: 0;
    }

    .ad-fill {
        padding: 12px 120px 12px 12px;
        font-size: 12px;
        border-radius: inherit;
        flex: 1;
    }

    .ad-aside {
        padding: 12px;
        font-size: 12px;
        flex: 1;
        color: $black;
    }

    .ad-fill-image {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .ad-button-link {
        border: none;
        padding: 8px 10px;
        border-radius: 10px;
        transition: 0.2s;
    }

    .ad-item-container {
        display: flex;
        align-items: baseline;
        gap: 5px;

        img {
            display: block;
        }
    }
}

.ad-float-container {
    margin: 0 0 16px 16px;
    float: right;

    img {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
}

.ad-footnote {
    font-size: 12px;
    color: #8d949c;
}

.superscript {
    vertical-align: super;
    font-size: 0.6rem;
}

/* ------------------------- Dashboard  ------------------------- */

#dashboard-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100vh - var(--navbar-height));
    background-color: #0c1219;
    box-shadow: 0 10px 20px 20px #0c1219;
}

/* ------------------------- Fallback for Edge   ------------------------- */

@supports (-ms-ime-align: auto) {
    .form-label-group > label {
        display: none;
    }

    .formgroup input::placeholder {
        color: #28283d;
    }
}

/* ------------------------- Fallback for IE --------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-label-group > label {
        display: none;
    }

    .formgroup input::placeholder {
        color: #28283d;
    }
}

/* ––––––––––––––––––––––– MEDIA QUERIES ––––––––––––––––––––––––––– */

/* ----- TABLETS ----- */
@media screen and (max-width: 991px) {
    .small-letter-text {
        margin-bottom: -20px;
    }

    .text-span {
        font-size: 17px !important;
    }

    .blue-data-table {
        .data-table-header {
            font-size: 18px !important;
        }

        .data-table-content {
            font-size: 18px !important;
            padding: 15px 10px !important;

            .big-metric-value {
                font-size: 27px;
            }
        }
    }

    .form-group-row {
        flex-wrap: wrap;
    }

    #nav {
        padding: 10px 20px;
    }

    .links-container {
        width: 100%;
        justify-content: space-around;
        margin: 0 0 20px;
    }

    .links-container ul {
        margin: 0;
    }

    .copyright-container {
        text-align: center;
        margin: 0;
    }

    .subscription-container {
        width: 100%;
        padding: 20px;
    }

    .card-link-container {
        flex-direction: column;
    }

    .card-success-container {
        width: 90%;
        flex-direction: column;
    }

    .text-success-container {
        width: 100%;
    }

    .floating-label {
        max-width: 100px;
        font-size: 14px;
        margin-right: 15px;
    }

    .brand-container {
        .brand-item {
            &.source-item {
                width: 100%;
                flex: auto;
                order: initial;
                margin-bottom: 30px;
            }
        }
    }

    .coopervision-logo-macro {
        max-width: 153px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .coopervision-logo-break-margin {
        margin-right: -30px;
        margin-top: 0;
    }

    .ocumetra-logo-macro {
        max-width: 160px;

        p {
            margin-left: 45px;
        }
    }
}

/* -----  MOBILE (Smartphone) ----- */
@media screen and (max-width: 580px) {
    .ad-card-control .ad-fill {
        padding: 12px !important;
    }

    .card-base {
        width: 90%;
    }

    .card-manage {
        margin: 0;
    }

    .floating-label {
        max-width: 80px;
        margin-top: -20px;
        padding-top: 35px;
    }

    .coopervision-logo-macro {
        img {
            max-width: 100px;
        }

        p {
            font-size: 8px;
        }
    }

    .coopervision-logo-break-margin {
        margin-right: 0;
    }

    .blue-data-table {
        .data-table-header {
            font-size: 16px !important;
        }

        .data-table-content {
            .flex-row {
                flex-direction: column !important;
            }

            font-size: 15px !important;

            .big-metric-value {
                padding-bottom: 10px;
            }
        }
    }
}

#waiting-div {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 50;
    padding: 4rem;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0 0 0 / 35%) 0 5px 15px;
    background-color: rgba(255 255 255 / 80%);
    backdrop-filter: blur(5px);
}

.p-absolute {
    position: absolute !important;
}

.mt-n-75 {
    margin-top: -75px;
}

.mt-n-15 {
    margin-top: -15px;
}

/* -----  Blue data table component ----- */
.justify-content-space-between {
    justify-content: space-between;
}

.post-treatment {
    justify-content: center;

    .blue-data-table {
        flex: none;
        margin-bottom: 10px !important;
        margin-top: 10px;

        .data-table-content {
            padding: 25px 20px;
        }

        .data-table-content.dense {
            @include breakpoint($lg) {
                padding: 10px 17.5px 15px;
            }
        }
    }
}

.current-progression.blue-data-table {
    .data-table-content {
        padding: 25px 10px 35px;
    }
}

.blue-data-table.treatment-benefit {
    .data-table-content {
        padding: 10px;

        .row .col.predicted-text {
            padding: 0 20px;

            @include breakpoint($xs) {
                font-size: 14px;
            }

            @include breakpoint($xlx) {
                font-size: 16px;
            }
        }

        .row .col-auto.metric-value {
            @include breakpoint($xs) {
                font-size: 20px;
            }

            @include breakpoint($xlx) {
                font-size: 27px;
            }
        }
    }
}

.small-letter-text {
    font-size: 9px;
    margin-bottom: -25px;
}

.blue-data-table {
    width: 100%;
    margin-bottom: 1.7rem;
    text-align: center;
    display: flex;
    flex-direction: column;

    .data-table-header {
        font-weight: 700;
        font-size: 20px;
        line-height: 35px;
        border-radius: 15px 15px 0 0;
        width: 100%;
        padding: 8px;
        background-color: $blue-350;
        color: #fff;

        span {
            margin-left: 5px;

            .tooltip {
                display: block;
            }
        }
    }

    .data-table-content {
        width: 100%;
        font-size: 20px;
        line-height: 25px;
        padding: 30px 10px;
        border: 2px solid #e6e6ff;
        border-top-width: 0;
        border-radius: 0 0 15px 15px;
    }
}

.metric-value {
    font-size: 27px;
}

.big-metric-value {
    font-size: 34px;
}

.metric-value-limiter {
    max-width: 220px;
    line-height: 120%;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-500 {
    font-weight: 500;
}

.chart-frame {
    border: 2px solid #e6e6ff;
    border-top-width: 2px;
    border-radius: 10px;
    padding: 35px 30px;

    .chart-title {
        color: $blue-350;
        padding: 5px;
        font-size: 24px;
        font-weight: 600;

        @include breakpoint($base) {
            font-size: 14px;
        }

        @include breakpoint($sm) {
            font-size: 16px;
        }

        @include breakpoint($md) {
            font-size: 24px;
        }
    }

    .chart-subtitle {
        font-size: 18px;
        font-weight: 600;

        @include breakpoint($base) {
            font-size: 10px;
        }

        @include breakpoint($sm) {
            font-size: 12px;
        }

        @include breakpoint($md) {
            font-size: 18px;
        }
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.override-collapse {
    .tab-content &.tab-pane {
        display: grid;
        opacity: 0;
        transform: rotateY(180deg);
        transform-style: preserve-3d;
        backface-visibility: hidden;
        transition: transform 2s, opacity 2s;

        &.show {
            transform: rotateY(0deg);
            opacity: 1;
            transition: transform 2s, opacity 2s;
        }
    }
}

.grid-template {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.grid-column-1-2 {
    grid-column: 1/2;
}

.grid-row-1-2 {
    grid-row: 1/2;
}

.hover-card-container:first-child {
    padding-left: 0;
}

.hover-card-container:last-child {
    padding-right: 0;
}
