@-webkit-keyframes floating {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  50% {
    -webkit-transform: translate(0, 15px);
    transform: translate(0, 15px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes floating {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  50% {
    -webkit-transform: translate(0, 15px);
    transform: translate(0, 15px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.bgc-primary {
  background-color: #00725c;
}

.color-primary {
  color: #00725c;
}

.border-primary {
  border-color: #00725c;
}

.dark .bgc-dark-primary {
  background-color: #00725c;
}

.border-dark-primary {
  border-color: #00725c;
}

.dark .color-dark-primary {
  color: #00725c;
}

.bgc-secondary {
  background-color: #005252;
}

.color-secondary {
  color: #005252;
}

.border-secondary {
  border-color: #005252;
}

.dark .bgc-dark-secondary {
  background-color: #005252;
}

.border-dark-secondary {
  border-color: #005252;
}

.dark .color-dark-secondary {
  color: #005252;
}

.bgc-tertiary {
  background-color: #01a998;
}

.color-tertiary {
  color: #01a998;
}

.border-tertiary {
  border-color: #01a998;
}

.dark .bgc-dark-tertiary {
  background-color: #01a998;
}

.border-dark-tertiary {
  border-color: #01a998;
}

.dark .color-dark-tertiary {
  color: #01a998;
}

.bgc-warning-400 {
  background-color: #e9d502;
}

.color-warning-400 {
  color: #e9d502;
}

.border-warning-400 {
  border-color: #e9d502;
}

.dark .bgc-dark-warning-400 {
  background-color: #e9d502;
}

.border-dark-warning-400 {
  border-color: #e9d502;
}

.dark .color-dark-warning-400 {
  color: #e9d502;
}

.bgc-warning-500 {
  background-color: #af8d00;
}

.color-warning-500 {
  color: #af8d00;
}

.border-warning-500 {
  border-color: #af8d00;
}

.dark .bgc-dark-warning-500 {
  background-color: #af8d00;
}

.border-dark-warning-500 {
  border-color: #af8d00;
}

.dark .color-dark-warning-500 {
  color: #af8d00;
}

.bgc-success-400 {
  background-color: #4bb543;
}

.color-success-400 {
  color: #4bb543;
}

.border-success-400 {
  border-color: #4bb543;
}

.dark .bgc-dark-success-400 {
  background-color: #4bb543;
}

.border-dark-success-400 {
  border-color: #4bb543;
}

.dark .color-dark-success-400 {
  color: #4bb543;
}

.bgc-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.color-transparent {
  color: rgba(0, 0, 0, 0);
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.dark .bgc-dark-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.border-dark-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.dark .color-dark-transparent {
  color: rgba(0, 0, 0, 0);
}

.bgc-white {
  background-color: #fff;
}

.color-white {
  color: #fff;
}

.border-white {
  border-color: #fff;
}

.dark .bgc-dark-white {
  background-color: #fff;
}

.border-dark-white {
  border-color: #fff;
}

.dark .color-dark-white {
  color: #fff;
}

.bgc-white-250 {
  background-color: #fdfdfd;
}

.color-white-250 {
  color: #fdfdfd;
}

.border-white-250 {
  border-color: #fdfdfd;
}

.dark .bgc-dark-white-250 {
  background-color: #fdfdfd;
}

.border-dark-white-250 {
  border-color: #fdfdfd;
}

.dark .color-dark-white-250 {
  color: #fdfdfd;
}

.bgc-white-900 {
  background-color: #e6e6e8;
}

.color-white-900 {
  color: #e6e6e8;
}

.border-white-900 {
  border-color: #e6e6e8;
}

.dark .bgc-dark-white-900 {
  background-color: #e6e6e8;
}

.border-dark-white-900 {
  border-color: #e6e6e8;
}

.dark .color-dark-white-900 {
  color: #e6e6e8;
}

.bgc-white-alpha-10 {
  background-color: rgba(255, 255, 255, .1);
}

.color-white-alpha-10 {
  color: rgba(255, 255, 255, .1);
}

.border-white-alpha-10 {
  border-color: rgba(255, 255, 255, .1);
}

.dark .bgc-dark-white-alpha-10 {
  background-color: rgba(255, 255, 255, .1);
}

.border-dark-white-alpha-10 {
  border-color: rgba(255, 255, 255, .1);
}

.dark .color-dark-white-alpha-10 {
  color: rgba(255, 255, 255, .1);
}

.bgc-white-alpha-20 {
  background-color: rgba(255, 255, 255, .2);
}

.color-white-alpha-20 {
  color: rgba(255, 255, 255, .2);
}

.border-white-alpha-20 {
  border-color: rgba(255, 255, 255, .2);
}

.dark .bgc-dark-white-alpha-20 {
  background-color: rgba(255, 255, 255, .2);
}

.border-dark-white-alpha-20 {
  border-color: rgba(255, 255, 255, .2);
}

.dark .color-dark-white-alpha-20 {
  color: rgba(255, 255, 255, .2);
}

.bgc-black {
  background-color: #000;
}

.color-black {
  color: #000;
}

.border-black {
  border-color: #000;
}

.dark .bgc-dark-black {
  background-color: #000;
}

.border-dark-black {
  border-color: #000;
}

.dark .color-dark-black {
  color: #000;
}

.bgc-black-900 {
  background-color: #010314;
}

.color-black-900 {
  color: #010314;
}

.border-black-900 {
  border-color: #010314;
}

.dark .bgc-dark-black-900 {
  background-color: #010314;
}

.border-dark-black-900 {
  border-color: #010314;
}

.dark .color-dark-black-900 {
  color: #010314;
}

.bgc-red-200 {
  background-color: #ee8681;
}

.color-red-200 {
  color: #ee8681;
}

.border-red-200 {
  border-color: #ee8681;
}

.dark .bgc-dark-red-200 {
  background-color: #ee8681;
}

.border-dark-red-200 {
  border-color: #ee8681;
}

.dark .color-dark-red-200 {
  color: #ee8681;
}

.bgc-red-400 {
  background-color: red;
}

.color-red-400 {
  color: red;
}

.border-red-400 {
  border-color: red;
}

.dark .bgc-dark-red-400 {
  background-color: red;
}

.border-dark-red-400 {
  border-color: red;
}

.dark .color-dark-red-400 {
  color: red;
}

.bgc-pale-blue-400 {
  background-color: #f7f8fc;
}

.color-pale-blue-400 {
  color: #f7f8fc;
}

.border-pale-blue-400 {
  border-color: #f7f8fc;
}

.dark .bgc-dark-pale-blue-400 {
  background-color: #f7f8fc;
}

.border-dark-pale-blue-400 {
  border-color: #f7f8fc;
}

.dark .color-dark-pale-blue-400 {
  color: #f7f8fc;
}

.bgc-pale-blue-500 {
  background-color: #f0f5ff;
}

.color-pale-blue-500 {
  color: #f0f5ff;
}

.border-pale-blue-500 {
  border-color: #f0f5ff;
}

.dark .bgc-dark-pale-blue-500 {
  background-color: #f0f5ff;
}

.border-dark-pale-blue-500 {
  border-color: #f0f5ff;
}

.dark .color-dark-pale-blue-500 {
  color: #f0f5ff;
}

.bgc-light-blue-400 {
  background-color: #2ca8b1;
}

.color-light-blue-400 {
  color: #2ca8b1;
}

.border-light-blue-400 {
  border-color: #2ca8b1;
}

.dark .bgc-dark-light-blue-400 {
  background-color: #2ca8b1;
}

.border-dark-light-blue-400 {
  border-color: #2ca8b1;
}

.dark .color-dark-light-blue-400 {
  color: #2ca8b1;
}

.bgc-blue-50 {
  background-color: #c6c5ff;
}

.color-blue-50 {
  color: #c6c5ff;
}

.border-blue-50 {
  border-color: #c6c5ff;
}

.dark .bgc-dark-blue-50 {
  background-color: #c6c5ff;
}

.border-dark-blue-50 {
  border-color: #c6c5ff;
}

.dark .color-dark-blue-50 {
  color: #c6c5ff;
}

.bgc-blue-200 {
  background-color: #8d8dff;
}

.color-blue-200 {
  color: #8d8dff;
}

.border-blue-200 {
  border-color: #8d8dff;
}

.dark .bgc-dark-blue-200 {
  background-color: #8d8dff;
}

.border-dark-blue-200 {
  border-color: #8d8dff;
}

.dark .color-dark-blue-200 {
  color: #8d8dff;
}

.bgc-blue-225 {
  background-color: #6366f1;
}

.color-blue-225 {
  color: #6366f1;
}

.border-blue-225 {
  border-color: #6366f1;
}

.dark .bgc-dark-blue-225 {
  background-color: #6366f1;
}

.border-dark-blue-225 {
  border-color: #6366f1;
}

.dark .color-dark-blue-225 {
  color: #6366f1;
}

.bgc-blue-250 {
  background-color: #3b3beb;
}

.color-blue-250 {
  color: #3b3beb;
}

.border-blue-250 {
  border-color: #3b3beb;
}

.dark .bgc-dark-blue-250 {
  background-color: #3b3beb;
}

.border-dark-blue-250 {
  border-color: #3b3beb;
}

.dark .color-dark-blue-250 {
  color: #3b3beb;
}

.bgc-blue-300 {
  background-color: #3838f8;
}

.color-blue-300 {
  color: #3838f8;
}

.border-blue-300 {
  border-color: #3838f8;
}

.dark .bgc-dark-blue-300 {
  background-color: #3838f8;
}

.border-dark-blue-300 {
  border-color: #3838f8;
}

.dark .color-dark-blue-300 {
  color: #3838f8;
}

.bgc-blue-350 {
  background-color: #3a3af2;
}

.color-blue-350 {
  color: #3a3af2;
}

.border-blue-350 {
  border-color: #3a3af2;
}

.dark .bgc-dark-blue-350 {
  background-color: #3a3af2;
}

.border-dark-blue-350 {
  border-color: #3a3af2;
}

.dark .color-dark-blue-350 {
  color: #3a3af2;
}

.bgc-blue-400 {
  background-color: #3a3aea;
}

.color-blue-400 {
  color: #3a3aea;
}

.border-blue-400 {
  border-color: #3a3aea;
}

.dark .bgc-dark-blue-400 {
  background-color: #3a3aea;
}

.border-dark-blue-400 {
  border-color: #3a3aea;
}

.dark .color-dark-blue-400 {
  color: #3a3aea;
}

.bgc-blue-450 {
  background-color: #443ae1;
}

.color-blue-450 {
  color: #443ae1;
}

.border-blue-450 {
  border-color: #443ae1;
}

.dark .bgc-dark-blue-450 {
  background-color: #443ae1;
}

.border-dark-blue-450 {
  border-color: #443ae1;
}

.dark .color-dark-blue-450 {
  color: #443ae1;
}

.bgc-blue-500 {
  background-color: #0303d1;
}

.color-blue-500 {
  color: #0303d1;
}

.border-blue-500 {
  border-color: #0303d1;
}

.dark .bgc-dark-blue-500 {
  background-color: #0303d1;
}

.border-dark-blue-500 {
  border-color: #0303d1;
}

.dark .color-dark-blue-500 {
  color: #0303d1;
}

.bgc-blue-600 {
  background-color: #0000ad;
}

.color-blue-600 {
  color: #0000ad;
}

.border-blue-600 {
  border-color: #0000ad;
}

.dark .bgc-dark-blue-600 {
  background-color: #0000ad;
}

.border-dark-blue-600 {
  border-color: #0000ad;
}

.dark .color-dark-blue-600 {
  color: #0000ad;
}

.bgc-blue-800 {
  background-color: #000081;
}

.color-blue-800 {
  color: #000081;
}

.border-blue-800 {
  border-color: #000081;
}

.dark .bgc-dark-blue-800 {
  background-color: #000081;
}

.border-dark-blue-800 {
  border-color: #000081;
}

.dark .color-dark-blue-800 {
  color: #000081;
}

.bgc-navy-blue-100 {
  background-color: #80b5f5;
}

.color-navy-blue-100 {
  color: #80b5f5;
}

.border-navy-blue-100 {
  border-color: #80b5f5;
}

.dark .bgc-dark-navy-blue-100 {
  background-color: #80b5f5;
}

.border-dark-navy-blue-100 {
  border-color: #80b5f5;
}

.dark .color-dark-navy-blue-100 {
  color: #80b5f5;
}

.bgc-navy-blue-400 {
  background-color: #005298;
}

.color-navy-blue-400 {
  color: #005298;
}

.border-navy-blue-400 {
  border-color: #005298;
}

.dark .bgc-dark-navy-blue-400 {
  background-color: #005298;
}

.border-dark-navy-blue-400 {
  border-color: #005298;
}

.dark .color-dark-navy-blue-400 {
  color: #005298;
}

.bgc-navy-blue-300 {
  background-color: #2b77bd;
}

.color-navy-blue-300 {
  color: #2b77bd;
}

.border-navy-blue-300 {
  border-color: #2b77bd;
}

.dark .bgc-dark-navy-blue-300 {
  background-color: #2b77bd;
}

.border-dark-navy-blue-300 {
  border-color: #2b77bd;
}

.dark .color-dark-navy-blue-300 {
  color: #2b77bd;
}

.bgc-navy-blue-500 {
  background-color: #2b517c;
}

.color-navy-blue-500 {
  color: #2b517c;
}

.border-navy-blue-500 {
  border-color: #2b517c;
}

.dark .bgc-dark-navy-blue-500 {
  background-color: #2b517c;
}

.border-dark-navy-blue-500 {
  border-color: #2b517c;
}

.dark .color-dark-navy-blue-500 {
  color: #2b517c;
}

.bgc-navy-blue-600 {
  background-color: #2a537e;
}

.color-navy-blue-600 {
  color: #2a537e;
}

.border-navy-blue-600 {
  border-color: #2a537e;
}

.dark .bgc-dark-navy-blue-600 {
  background-color: #2a537e;
}

.border-dark-navy-blue-600 {
  border-color: #2a537e;
}

.dark .color-dark-navy-blue-600 {
  color: #2a537e;
}

.bgc-navy-blue-700 {
  background-color: #1d3b5e;
}

.color-navy-blue-700 {
  color: #1d3b5e;
}

.border-navy-blue-700 {
  border-color: #1d3b5e;
}

.dark .bgc-dark-navy-blue-700 {
  background-color: #1d3b5e;
}

.border-dark-navy-blue-700 {
  border-color: #1d3b5e;
}

.dark .color-dark-navy-blue-700 {
  color: #1d3b5e;
}

.bgc-navy-blue-800 {
  background-color: #152b4b;
}

.color-navy-blue-800 {
  color: #152b4b;
}

.border-navy-blue-800 {
  border-color: #152b4b;
}

.dark .bgc-dark-navy-blue-800 {
  background-color: #152b4b;
}

.border-dark-navy-blue-800 {
  border-color: #152b4b;
}

.dark .color-dark-navy-blue-800 {
  color: #152b4b;
}

.bgc-dark-blue-300 {
  background-color: #012c3c;
}

.color-dark-blue-300 {
  color: #012c3c;
}

.border-dark-blue-300 {
  border-color: #012c3c;
}

.dark .bgc-dark-dark-blue-300 {
  background-color: #012c3c;
}

.border-dark-dark-blue-300 {
  border-color: #012c3c;
}

.dark .color-dark-dark-blue-300 {
  color: #012c3c;
}

.bgc-dark-blue-350 {
  background-color: #002432;
}

.color-dark-blue-350 {
  color: #002432;
}

.border-dark-blue-350 {
  border-color: #002432;
}

.dark .bgc-dark-dark-blue-350 {
  background-color: #002432;
}

.border-dark-dark-blue-350 {
  border-color: #002432;
}

.dark .color-dark-dark-blue-350 {
  color: #002432;
}

.bgc-dark-blue-400 {
  background-color: #001923;
}

.color-dark-blue-400 {
  color: #001923;
}

.border-dark-blue-400 {
  border-color: #001923;
}

.dark .bgc-dark-dark-blue-400 {
  background-color: #001923;
}

.border-dark-dark-blue-400 {
  border-color: #001923;
}

.dark .color-dark-dark-blue-400 {
  color: #001923;
}

.bgc-dark-blue-500 {
  background-color: #000517;
}

.color-dark-blue-500 {
  color: #000517;
}

.border-dark-blue-500 {
  border-color: #000517;
}

.dark .bgc-dark-dark-blue-500 {
  background-color: #000517;
}

.border-dark-dark-blue-500 {
  border-color: #000517;
}

.dark .color-dark-dark-blue-500 {
  color: #000517;
}

.bgc-light-gray-50 {
  background-color: rgba(245, 245, 245, .796);
}

.color-light-gray-50 {
  color: rgba(245, 245, 245, .796);
}

.border-light-gray-50 {
  border-color: rgba(245, 245, 245, .796);
}

.dark .bgc-dark-light-gray-50 {
  background-color: rgba(245, 245, 245, .796);
}

.border-dark-light-gray-50 {
  border-color: rgba(245, 245, 245, .796);
}

.dark .color-dark-light-gray-50 {
  color: rgba(245, 245, 245, .796);
}

.bgc-light-gray-100 {
  background-color: #f5f5f5;
}

.color-light-gray-100 {
  color: #f5f5f5;
}

.border-light-gray-100 {
  border-color: #f5f5f5;
}

.dark .bgc-dark-light-gray-100 {
  background-color: #f5f5f5;
}

.border-dark-light-gray-100 {
  border-color: #f5f5f5;
}

.dark .color-dark-light-gray-100 {
  color: #f5f5f5;
}

.bgc-light-gray-125 {
  background-color: #f7f7f7;
}

.color-light-gray-125 {
  color: #f7f7f7;
}

.border-light-gray-125 {
  border-color: #f7f7f7;
}

.dark .bgc-dark-light-gray-125 {
  background-color: #f7f7f7;
}

.border-dark-light-gray-125 {
  border-color: #f7f7f7;
}

.dark .color-dark-light-gray-125 {
  color: #f7f7f7;
}

.bgc-light-gray-150 {
  background-color: #e3e3e3;
}

.color-light-gray-150 {
  color: #e3e3e3;
}

.border-light-gray-150 {
  border-color: #e3e3e3;
}

.dark .bgc-dark-light-gray-150 {
  background-color: #e3e3e3;
}

.border-dark-light-gray-150 {
  border-color: #e3e3e3;
}

.dark .color-dark-light-gray-150 {
  color: #e3e3e3;
}

.bgc-light-gray-175 {
  background-color: #dcdcdc;
}

.color-light-gray-175 {
  color: #dcdcdc;
}

.border-light-gray-175 {
  border-color: #dcdcdc;
}

.dark .bgc-dark-light-gray-175 {
  background-color: #dcdcdc;
}

.border-dark-light-gray-175 {
  border-color: #dcdcdc;
}

.dark .color-dark-light-gray-175 {
  color: #dcdcdc;
}

.bgc-light-gray-200 {
  background-color: #e5e5e5;
}

.color-light-gray-200 {
  color: #e5e5e5;
}

.border-light-gray-200 {
  border-color: #e5e5e5;
}

.dark .bgc-dark-light-gray-200 {
  background-color: #e5e5e5;
}

.border-dark-light-gray-200 {
  border-color: #e5e5e5;
}

.dark .color-dark-light-gray-200 {
  color: #e5e5e5;
}

.bgc-light-gray-300 {
  background-color: #ebebeb;
}

.color-light-gray-300 {
  color: #ebebeb;
}

.border-light-gray-300 {
  border-color: #ebebeb;
}

.dark .bgc-dark-light-gray-300 {
  background-color: #ebebeb;
}

.border-dark-light-gray-300 {
  border-color: #ebebeb;
}

.dark .color-dark-light-gray-300 {
  color: #ebebeb;
}

.bgc-light-gray-350 {
  background-color: #dedede;
}

.color-light-gray-350 {
  color: #dedede;
}

.border-light-gray-350 {
  border-color: #dedede;
}

.dark .bgc-dark-light-gray-350 {
  background-color: #dedede;
}

.border-dark-light-gray-350 {
  border-color: #dedede;
}

.dark .color-dark-light-gray-350 {
  color: #dedede;
}

.bgc-light-gray-400 {
  background-color: #d6d6d6;
}

.color-light-gray-400 {
  color: #d6d6d6;
}

.border-light-gray-400 {
  border-color: #d6d6d6;
}

.dark .bgc-dark-light-gray-400 {
  background-color: #d6d6d6;
}

.border-dark-light-gray-400 {
  border-color: #d6d6d6;
}

.dark .color-dark-light-gray-400 {
  color: #d6d6d6;
}

.bgc-light-gray-450 {
  background-color: #fcfcfc;
}

.color-light-gray-450 {
  color: #fcfcfc;
}

.border-light-gray-450 {
  border-color: #fcfcfc;
}

.dark .bgc-dark-light-gray-450 {
  background-color: #fcfcfc;
}

.border-dark-light-gray-450 {
  border-color: #fcfcfc;
}

.dark .color-dark-light-gray-450 {
  color: #fcfcfc;
}

.bgc-light-purple-100 {
  background-color: #e6e6ff;
}

.color-light-purple-100 {
  color: #e6e6ff;
}

.border-light-purple-100 {
  border-color: #e6e6ff;
}

.dark .bgc-dark-light-purple-100 {
  background-color: #e6e6ff;
}

.border-dark-light-purple-100 {
  border-color: #e6e6ff;
}

.dark .color-dark-light-purple-100 {
  color: #e6e6ff;
}

.bgc-light-purple-200 {
  background-color: #f2e9ff;
}

.color-light-purple-200 {
  color: #f2e9ff;
}

.border-light-purple-200 {
  border-color: #f2e9ff;
}

.dark .bgc-dark-light-purple-200 {
  background-color: #f2e9ff;
}

.border-dark-light-purple-200 {
  border-color: #f2e9ff;
}

.dark .color-dark-light-purple-200 {
  color: #f2e9ff;
}

.bgc-light-purple-500 {
  background-color: #a7a7f9;
}

.color-light-purple-500 {
  color: #a7a7f9;
}

.border-light-purple-500 {
  border-color: #a7a7f9;
}

.dark .bgc-dark-light-purple-500 {
  background-color: #a7a7f9;
}

.border-dark-light-purple-500 {
  border-color: #a7a7f9;
}

.dark .color-dark-light-purple-500 {
  color: #a7a7f9;
}

.bgc-purple-200 {
  background-color: #362547;
}

.color-purple-200 {
  color: #362547;
}

.border-purple-200 {
  border-color: #362547;
}

.dark .bgc-dark-purple-200 {
  background-color: #362547;
}

.border-dark-purple-200 {
  border-color: #362547;
}

.dark .color-dark-purple-200 {
  color: #362547;
}

.bgc-purple-400 {
  background-color: #7120ce;
}

.color-purple-400 {
  color: #7120ce;
}

.border-purple-400 {
  border-color: #7120ce;
}

.dark .bgc-dark-purple-400 {
  background-color: #7120ce;
}

.border-dark-purple-400 {
  border-color: #7120ce;
}

.dark .color-dark-purple-400 {
  color: #7120ce;
}

.bgc-purple-500 {
  background-color: #6e37bd;
}

.color-purple-500 {
  color: #6e37bd;
}

.border-purple-500 {
  border-color: #6e37bd;
}

.dark .bgc-dark-purple-500 {
  background-color: #6e37bd;
}

.border-dark-purple-500 {
  border-color: #6e37bd;
}

.dark .color-dark-purple-500 {
  color: #6e37bd;
}

.bgc-gray-200 {
  background-color: #e1e1e1;
}

.color-gray-200 {
  color: #e1e1e1;
}

.border-gray-200 {
  border-color: #e1e1e1;
}

.dark .bgc-dark-gray-200 {
  background-color: #e1e1e1;
}

.border-dark-gray-200 {
  border-color: #e1e1e1;
}

.dark .color-dark-gray-200 {
  color: #e1e1e1;
}

.bgc-gray-225 {
  background-color: #d9d9d9;
}

.color-gray-225 {
  color: #d9d9d9;
}

.border-gray-225 {
  border-color: #d9d9d9;
}

.dark .bgc-dark-gray-225 {
  background-color: #d9d9d9;
}

.border-dark-gray-225 {
  border-color: #d9d9d9;
}

.dark .color-dark-gray-225 {
  color: #d9d9d9;
}

.bgc-gray-250 {
  background-color: #d3d3d3;
}

.color-gray-250 {
  color: #d3d3d3;
}

.border-gray-250 {
  border-color: #d3d3d3;
}

.dark .bgc-dark-gray-250 {
  background-color: #d3d3d3;
}

.border-dark-gray-250 {
  border-color: #d3d3d3;
}

.dark .color-dark-gray-250 {
  color: #d3d3d3;
}

.bgc-gray-300 {
  background-color: #c7c7c7;
}

.color-gray-300 {
  color: #c7c7c7;
}

.border-gray-300 {
  border-color: #c7c7c7;
}

.dark .bgc-dark-gray-300 {
  background-color: #c7c7c7;
}

.border-dark-gray-300 {
  border-color: #c7c7c7;
}

.dark .color-dark-gray-300 {
  color: #c7c7c7;
}

.bgc-gray-350 {
  background-color: #bababa;
}

.color-gray-350 {
  color: #bababa;
}

.border-gray-350 {
  border-color: #bababa;
}

.dark .bgc-dark-gray-350 {
  background-color: #bababa;
}

.border-dark-gray-350 {
  border-color: #bababa;
}

.dark .color-dark-gray-350 {
  color: #bababa;
}

.bgc-gray-360 {
  background-color: #bdbdbd;
}

.color-gray-360 {
  color: #bdbdbd;
}

.border-gray-360 {
  border-color: #bdbdbd;
}

.dark .bgc-dark-gray-360 {
  background-color: #bdbdbd;
}

.border-dark-gray-360 {
  border-color: #bdbdbd;
}

.dark .color-dark-gray-360 {
  color: #bdbdbd;
}

.bgc-gray-375 {
  background-color: #8e95a2;
}

.color-gray-375 {
  color: #8e95a2;
}

.border-gray-375 {
  border-color: #8e95a2;
}

.dark .bgc-dark-gray-375 {
  background-color: #8e95a2;
}

.border-dark-gray-375 {
  border-color: #8e95a2;
}

.dark .color-dark-gray-375 {
  color: #8e95a2;
}

.bgc-gray-400 {
  background-color: #949494;
}

.color-gray-400 {
  color: #949494;
}

.border-gray-400 {
  border-color: #949494;
}

.dark .bgc-dark-gray-400 {
  background-color: #949494;
}

.border-dark-gray-400 {
  border-color: #949494;
}

.dark .color-dark-gray-400 {
  color: #949494;
}

.bgc-medium-gray {
  background-color: #b7b7b7;
}

.color-medium-gray {
  color: #b7b7b7;
}

.border-medium-gray {
  border-color: #b7b7b7;
}

.dark .bgc-dark-medium-gray {
  background-color: #b7b7b7;
}

.border-dark-medium-gray {
  border-color: #b7b7b7;
}

.dark .color-dark-medium-gray {
  color: #b7b7b7;
}

.bgc-dark-gray-100 {
  background-color: #efefef;
}

.color-dark-gray-100 {
  color: #efefef;
}

.border-dark-gray-100 {
  border-color: #efefef;
}

.dark .bgc-dark-dark-gray-100 {
  background-color: #efefef;
}

.border-dark-dark-gray-100 {
  border-color: #efefef;
}

.dark .color-dark-dark-gray-100 {
  color: #efefef;
}

.bgc-dark-gray-150 {
  background-color: #7e7e7e;
}

.color-dark-gray-150 {
  color: #7e7e7e;
}

.border-dark-gray-150 {
  border-color: #7e7e7e;
}

.dark .bgc-dark-dark-gray-150 {
  background-color: #7e7e7e;
}

.border-dark-dark-gray-150 {
  border-color: #7e7e7e;
}

.dark .color-dark-dark-gray-150 {
  color: #7e7e7e;
}

.bgc-dark-gray-200 {
  background-color: #707070;
}

.color-dark-gray-200 {
  color: #707070;
}

.border-dark-gray-200 {
  border-color: #707070;
}

.dark .bgc-dark-dark-gray-200 {
  background-color: #707070;
}

.border-dark-dark-gray-200 {
  border-color: #707070;
}

.dark .color-dark-dark-gray-200 {
  color: #707070;
}

.bgc-dark-gray-300 {
  background-color: rgba(255, 255, 255, .3);
}

.color-dark-gray-300 {
  color: rgba(255, 255, 255, .3);
}

.border-dark-gray-300 {
  border-color: rgba(255, 255, 255, .3);
}

.dark .bgc-dark-dark-gray-300 {
  background-color: rgba(255, 255, 255, .3);
}

.border-dark-dark-gray-300 {
  border-color: rgba(255, 255, 255, .3);
}

.dark .color-dark-dark-gray-300 {
  color: rgba(255, 255, 255, .3);
}

.bgc-dark-gray-350 {
  background-color: #696969;
}

.color-dark-gray-350 {
  color: #696969;
}

.border-dark-gray-350 {
  border-color: #696969;
}

.dark .bgc-dark-dark-gray-350 {
  background-color: #696969;
}

.border-dark-dark-gray-350 {
  border-color: #696969;
}

.dark .color-dark-dark-gray-350 {
  color: #696969;
}

.bgc-dark-gray-400 {
  background-color: #5a5a5a;
}

.color-dark-gray-400 {
  color: #5a5a5a;
}

.border-dark-gray-400 {
  border-color: #5a5a5a;
}

.dark .bgc-dark-dark-gray-400 {
  background-color: #5a5a5a;
}

.border-dark-dark-gray-400 {
  border-color: #5a5a5a;
}

.dark .color-dark-dark-gray-400 {
  color: #5a5a5a;
}

.bgc-dark-gray-450 {
  background-color: #615e5e;
}

.color-dark-gray-450 {
  color: #615e5e;
}

.border-dark-gray-450 {
  border-color: #615e5e;
}

.dark .bgc-dark-dark-gray-450 {
  background-color: #615e5e;
}

.border-dark-dark-gray-450 {
  border-color: #615e5e;
}

.dark .color-dark-dark-gray-450 {
  color: #615e5e;
}

.bgc-dark-gray-500 {
  background-color: #4b4b4b;
}

.color-dark-gray-500 {
  color: #4b4b4b;
}

.border-dark-gray-500 {
  border-color: #4b4b4b;
}

.dark .bgc-dark-dark-gray-500 {
  background-color: #4b4b4b;
}

.border-dark-dark-gray-500 {
  border-color: #4b4b4b;
}

.dark .color-dark-dark-gray-500 {
  color: #4b4b4b;
}

.bgc-dark-gray-700 {
  background-color: #222;
}

.color-dark-gray-700 {
  color: #222;
}

.border-dark-gray-700 {
  border-color: #222;
}

.dark .bgc-dark-dark-gray-700 {
  background-color: #222;
}

.border-dark-dark-gray-700 {
  border-color: #222;
}

.dark .color-dark-dark-gray-700 {
  color: #222;
}

.bgc-dark-gray-800 {
  background-color: #121212;
}

.color-dark-gray-800 {
  color: #121212;
}

.border-dark-gray-800 {
  border-color: #121212;
}

.dark .bgc-dark-dark-gray-800 {
  background-color: #121212;
}

.border-dark-dark-gray-800 {
  border-color: #121212;
}

.dark .color-dark-dark-gray-800 {
  color: #121212;
}

.bgc-teal-400 {
  background-color: #01a998;
}

.color-teal-400 {
  color: #01a998;
}

.border-teal-400 {
  border-color: #01a998;
}

.dark .bgc-dark-teal-400 {
  background-color: #01a998;
}

.border-dark-teal-400 {
  border-color: #01a998;
}

.dark .color-dark-teal-400 {
  color: #01a998;
}

.bgc-teal-600 {
  background-color: #006464;
}

.color-teal-600 {
  color: #006464;
}

.border-teal-600 {
  border-color: #006464;
}

.dark .bgc-dark-teal-600 {
  background-color: #006464;
}

.border-dark-teal-600 {
  border-color: #006464;
}

.dark .color-dark-teal-600 {
  color: #006464;
}

.bgc-teal-650 {
  background-color: #025858;
}

.color-teal-650 {
  color: #025858;
}

.border-teal-650 {
  border-color: #025858;
}

.dark .bgc-dark-teal-650 {
  background-color: #025858;
}

.border-dark-teal-650 {
  border-color: #025858;
}

.dark .color-dark-teal-650 {
  color: #025858;
}

.bgc-teal-675 {
  background-color: #035577;
}

.color-teal-675 {
  color: #035577;
}

.border-teal-675 {
  border-color: #035577;
}

.dark .bgc-dark-teal-675 {
  background-color: #035577;
}

.border-dark-teal-675 {
  border-color: #035577;
}

.dark .color-dark-teal-675 {
  color: #035577;
}

.bgc-teal-700 {
  background-color: #043f3f;
}

.color-teal-700 {
  color: #043f3f;
}

.border-teal-700 {
  border-color: #043f3f;
}

.dark .bgc-dark-teal-700 {
  background-color: #043f3f;
}

.border-dark-teal-700 {
  border-color: #043f3f;
}

.dark .color-dark-teal-700 {
  color: #043f3f;
}

.bgc-teal-800 {
  background-color: #003d3d;
}

.color-teal-800 {
  color: #003d3d;
}

.border-teal-800 {
  border-color: #003d3d;
}

.dark .bgc-dark-teal-800 {
  background-color: #003d3d;
}

.border-dark-teal-800 {
  border-color: #003d3d;
}

.dark .color-dark-teal-800 {
  color: #003d3d;
}

.bgc-dark-teal-400 {
  background-color: #02192d;
}

.color-dark-teal-400 {
  color: #02192d;
}

.border-dark-teal-400 {
  border-color: #02192d;
}

.dark .bgc-dark-dark-teal-400 {
  background-color: #02192d;
}

.border-dark-dark-teal-400 {
  border-color: #02192d;
}

.dark .color-dark-dark-teal-400 {
  color: #02192d;
}

.bgc-dark-teal-500 {
  background-color: #011923;
}

.color-dark-teal-500 {
  color: #011923;
}

.border-dark-teal-500 {
  border-color: #011923;
}

.dark .bgc-dark-dark-teal-500 {
  background-color: #011923;
}

.border-dark-dark-teal-500 {
  border-color: #011923;
}

.dark .color-dark-dark-teal-500 {
  color: #011923;
}

.bgc-green-200 {
  background-color: #90bf6f;
}

.color-green-200 {
  color: #90bf6f;
}

.border-green-200 {
  border-color: #90bf6f;
}

.dark .bgc-dark-green-200 {
  background-color: #90bf6f;
}

.border-dark-green-200 {
  border-color: #90bf6f;
}

.dark .color-dark-green-200 {
  color: #90bf6f;
}

.bgc-green-400 {
  background-color: #6ccc82;
}

.color-green-400 {
  color: #6ccc82;
}

.border-green-400 {
  border-color: #6ccc82;
}

.dark .bgc-dark-green-400 {
  background-color: #6ccc82;
}

.border-dark-green-400 {
  border-color: #6ccc82;
}

.dark .color-dark-green-400 {
  color: #6ccc82;
}

.bgc-light-green-400 {
  background-color: #c5eef1;
}

.color-light-green-400 {
  color: #c5eef1;
}

.border-light-green-400 {
  border-color: #c5eef1;
}

.dark .bgc-dark-light-green-400 {
  background-color: #c5eef1;
}

.border-dark-light-green-400 {
  border-color: #c5eef1;
}

.dark .color-dark-light-green-400 {
  color: #c5eef1;
}

.bgc-persian-green-200 {
  background-color: #00e6ba;
}

.color-persian-green-200 {
  color: #00e6ba;
}

.border-persian-green-200 {
  border-color: #00e6ba;
}

.dark .bgc-dark-persian-green-200 {
  background-color: #00e6ba;
}

.border-dark-persian-green-200 {
  border-color: #00e6ba;
}

.dark .color-dark-persian-green-200 {
  color: #00e6ba;
}

.bgc-persian-green-300 {
  background-color: #00c7a2;
}

.color-persian-green-300 {
  color: #00c7a2;
}

.border-persian-green-300 {
  border-color: #00c7a2;
}

.dark .bgc-dark-persian-green-300 {
  background-color: #00c7a2;
}

.border-dark-persian-green-300 {
  border-color: #00c7a2;
}

.dark .color-dark-persian-green-300 {
  color: #00c7a2;
}

.bgc-persian-green {
  background-color: #009e80;
}

.color-persian-green {
  color: #009e80;
}

.border-persian-green {
  border-color: #009e80;
}

.dark .bgc-dark-persian-green {
  background-color: #009e80;
}

.border-dark-persian-green {
  border-color: #009e80;
}

.dark .color-dark-persian-green {
  color: #009e80;
}

.bgc-pink-400 {
  background-color: #ee4d99;
}

.color-pink-400 {
  color: #ee4d99;
}

.border-pink-400 {
  border-color: #ee4d99;
}

.dark .bgc-dark-pink-400 {
  background-color: #ee4d99;
}

.border-dark-pink-400 {
  border-color: #ee4d99;
}

.dark .color-dark-pink-400 {
  color: #ee4d99;
}

.bgc-pink-500 {
  background-color: #cc2e78;
}

.color-pink-500 {
  color: #cc2e78;
}

.border-pink-500 {
  border-color: #cc2e78;
}

.dark .bgc-dark-pink-500 {
  background-color: #cc2e78;
}

.border-dark-pink-500 {
  border-color: #cc2e78;
}

.dark .color-dark-pink-500 {
  color: #cc2e78;
}

.bgc-white-800 {
  background-color: #dde8e9;
}

.color-white-800 {
  color: #dde8e9;
}

.border-white-800 {
  border-color: #dde8e9;
}

.dark .bgc-dark-white-800 {
  background-color: #dde8e9;
}

.border-dark-white-800 {
  border-color: #dde8e9;
}

.dark .color-dark-white-800 {
  color: #dde8e9;
}

.bgc-blueish-gray-100 {
  background-color: #d4d7e5;
}

.color-blueish-gray-100 {
  color: #d4d7e5;
}

.border-blueish-gray-100 {
  border-color: #d4d7e5;
}

.dark .bgc-dark-blueish-gray-100 {
  background-color: #d4d7e5;
}

.border-dark-blueish-gray-100 {
  border-color: #d4d7e5;
}

.dark .color-dark-blueish-gray-100 {
  color: #d4d7e5;
}

.bgc-blueish-gray-700 {
  background-color: #565973;
}

.color-blueish-gray-700 {
  color: #565973;
}

.border-blueish-gray-700 {
  border-color: #565973;
}

.dark .bgc-dark-blueish-gray-700 {
  background-color: #565973;
}

.border-dark-blueish-gray-700 {
  border-color: #565973;
}

.dark .color-dark-blueish-gray-700 {
  color: #565973;
}

.bgc-dark-blue-550 {
  background-color: #0c1933;
}

.color-dark-blue-550 {
  color: #0c1933;
}

.border-dark-blue-550 {
  border-color: #0c1933;
}

.dark .bgc-dark-dark-blue-550 {
  background-color: #0c1933;
}

.border-dark-dark-blue-550 {
  border-color: #0c1933;
}

.dark .color-dark-dark-blue-550 {
  color: #0c1933;
}

.bgc-dark-blue-600 {
  background-color: #010314;
}

.color-dark-blue-600 {
  color: #010314;
}

.border-dark-blue-600 {
  border-color: #010314;
}

.dark .bgc-dark-dark-blue-600 {
  background-color: #010314;
}

.border-dark-dark-blue-600 {
  border-color: #010314;
}

.dark .color-dark-dark-blue-600 {
  color: #010314;
}

.bgc-magenta-600 {
  background-color: #b70edb;
}

.color-magenta-600 {
  color: #b70edb;
}

.border-magenta-600 {
  border-color: #b70edb;
}

.dark .bgc-dark-magenta-600 {
  background-color: #b70edb;
}

.border-dark-magenta-600 {
  border-color: #b70edb;
}

.dark .color-dark-magenta-600 {
  color: #b70edb;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Thin), local(NeueHaasUnica-Thin), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Extra Light), local(NeueHaasUnica-ExtraLight), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Light), local(NeueHaasUnica-Light), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Regular), local(NeueHaasUnica-Regular), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Medium), local(NeueHaasUnica-Medium), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Bold), local(NeueHaasUnica-Bold), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Extra Bold), local(NeueHaasUnica-ExtraBold), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtraBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Black), local(NeueHaasUnica-Black), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Black.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Extra Black), local(NeueHaasUnica-ExtraBlack), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtraBlack.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Thin Italic), local(NeueHaasUnica-ThinItalic), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica ExtLt Ita), local(NeueHaasUnica-ExtLtIta), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtLtIta.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Light Italic), local(NeueHaasUnica-LightItalic), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Italic), local(NeueHaasUnica-Italic), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Medium Italic), local(NeueHaasUnica-MediumItalic), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Bold Italic), local(NeueHaasUnica-BoldItalic), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica Bold Italic), local(NeueHaasUnica-BoldItalic), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-BoldItalic_1.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica ExtBd Ita), local(NeueHaasUnica-ExtraBoldItalic), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtraBoldItalic.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Unica;
  src: local(Neue Haas Unica ExtBlk Ita), local(NeueHaasUnica-ExtBlkIta), url("https://resources.ocumetra.com/fonts/NeueHaasUnica-ExtBlkIta.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: local(Montserrat Light), local(Montserrat-Light), url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: local(Montserrat Regular), local(Montserrat-Regular), url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: local(Montserrat Medium), local(Montserrat-Medium), url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: local(Montserrat SemiBold), local(Montserrat-SemiBold), url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: local(Montserrat Bold), local(Montserrat-Bold), url("https://resources.ocumetra.com/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: DMSans;
  src: local(DMSans Regular), local(DMSans-Regular), url("https://resources.ocumetra.com/fonts/dm_sans/DMSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: DMSans;
  src: local(DMSans Medium), local(DMSans-Medium), url("https://resources.ocumetra.com/fonts/dm_sans/DMSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: DMSans;
  src: local(DMSans Bold), local(DMSans-Bold), url("https://resources.ocumetra.com/fonts/dm_sans/DMSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e1e1e1;
  --bs-gray-300: #c7c7c7;
  --bs-gray-400: #949494;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #00725c;
  --bs-secondary: #005252;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 0, 114, 92;
  --bs-secondary-rgb: 0, 82, 82;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #c7c7c7;
  --bs-border-color-translucent: rgba(0, 0, 0, .176);
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #00725c;
  --bs-link-hover-color: #005b4a;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.container, .container-fluid, .container-xxl, .container-xlx, .container-lxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xxs, .container-xs {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 0;
}

@media (min-width: 390px) {
  .container-xxs, .container {
    max-width: 390px;
  }
}

@media (min-width: 640px) {
  .container-sm, .container-xxs, .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container-xxs, .container {
    max-width: 768px;
  }
}

@media (min-width: 1025px) {
  .container-lg, .container-md, .container-sm, .container-xxs, .container {
    max-width: 1025px;
  }
}

@media (min-width: 1280px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xxs, .container {
    max-width: 1280px;
  }
}

@media (min-width: 1400px) {
  .container-lxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xxs, .container {
    max-width: 1400px;
  }
}

@media (min-width: 1512px) {
  .container-xlx, .container-lxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xxs, .container {
    max-width: 1512px;
  }
}

@media (min-width: 1920px) {
  .container-xxl, .container-xlx, .container-lxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xxs, .container {
    max-width: 1920px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 390px) {
  .col-xxs {
    flex: 1 0;
  }

  .row-cols-xxs-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxs-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxs-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxs-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxs-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxs-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxs-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxs-auto {
    width: auto;
    flex: none;
  }

  .col-xxs-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxs-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxs-3 {
    width: 25%;
    flex: none;
  }

  .col-xxs-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxs-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxs-6 {
    width: 50%;
    flex: none;
  }

  .col-xxs-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxs-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxs-9 {
    width: 75%;
    flex: none;
  }

  .col-xxs-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxs-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxs-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxs-0 {
    margin-left: 0;
  }

  .offset-xxs-1 {
    margin-left: 8.33333%;
  }

  .offset-xxs-2 {
    margin-left: 16.6667%;
  }

  .offset-xxs-3 {
    margin-left: 25%;
  }

  .offset-xxs-4 {
    margin-left: 33.3333%;
  }

  .offset-xxs-5 {
    margin-left: 41.6667%;
  }

  .offset-xxs-6 {
    margin-left: 50%;
  }

  .offset-xxs-7 {
    margin-left: 58.3333%;
  }

  .offset-xxs-8 {
    margin-left: 66.6667%;
  }

  .offset-xxs-9 {
    margin-left: 75%;
  }

  .offset-xxs-10 {
    margin-left: 83.3333%;
  }

  .offset-xxs-11 {
    margin-left: 91.6667%;
  }

  .g-xxs-0, .gx-xxs-0 {
    --bs-gutter-x: 0;
  }

  .g-xxs-0, .gy-xxs-0 {
    --bs-gutter-y: 0;
  }

  .g-xxs-1, .gx-xxs-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxs-1, .gy-xxs-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxs-2, .gx-xxs-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxs-2, .gy-xxs-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxs-3, .gx-xxs-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxs-3, .gy-xxs-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxs-4, .gx-xxs-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxs-4, .gy-xxs-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxs-5, .gx-xxs-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxs-5, .gy-xxs-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 640px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1025px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1280px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-lxl {
    flex: 1 0;
  }

  .row-cols-lxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lxl-auto {
    width: auto;
    flex: none;
  }

  .col-lxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lxl-3 {
    width: 25%;
    flex: none;
  }

  .col-lxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lxl-6 {
    width: 50%;
    flex: none;
  }

  .col-lxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lxl-9 {
    width: 75%;
    flex: none;
  }

  .col-lxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-lxl-0 {
    margin-left: 0;
  }

  .offset-lxl-1 {
    margin-left: 8.33333%;
  }

  .offset-lxl-2 {
    margin-left: 16.6667%;
  }

  .offset-lxl-3 {
    margin-left: 25%;
  }

  .offset-lxl-4 {
    margin-left: 33.3333%;
  }

  .offset-lxl-5 {
    margin-left: 41.6667%;
  }

  .offset-lxl-6 {
    margin-left: 50%;
  }

  .offset-lxl-7 {
    margin-left: 58.3333%;
  }

  .offset-lxl-8 {
    margin-left: 66.6667%;
  }

  .offset-lxl-9 {
    margin-left: 75%;
  }

  .offset-lxl-10 {
    margin-left: 83.3333%;
  }

  .offset-lxl-11 {
    margin-left: 91.6667%;
  }

  .g-lxl-0, .gx-lxl-0 {
    --bs-gutter-x: 0;
  }

  .g-lxl-0, .gy-lxl-0 {
    --bs-gutter-y: 0;
  }

  .g-lxl-1, .gx-lxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lxl-1, .gy-lxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lxl-2, .gx-lxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lxl-2, .gy-lxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lxl-3, .gx-lxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lxl-3, .gy-lxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lxl-4, .gx-lxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lxl-4, .gy-lxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lxl-5, .gx-lxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lxl-5, .gy-lxl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1512px) {
  .col-xlx {
    flex: 1 0;
  }

  .row-cols-xlx-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xlx-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xlx-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xlx-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xlx-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xlx-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xlx-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xlx-auto {
    width: auto;
    flex: none;
  }

  .col-xlx-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xlx-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xlx-3 {
    width: 25%;
    flex: none;
  }

  .col-xlx-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xlx-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xlx-6 {
    width: 50%;
    flex: none;
  }

  .col-xlx-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xlx-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xlx-9 {
    width: 75%;
    flex: none;
  }

  .col-xlx-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xlx-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xlx-12 {
    width: 100%;
    flex: none;
  }

  .offset-xlx-0 {
    margin-left: 0;
  }

  .offset-xlx-1 {
    margin-left: 8.33333%;
  }

  .offset-xlx-2 {
    margin-left: 16.6667%;
  }

  .offset-xlx-3 {
    margin-left: 25%;
  }

  .offset-xlx-4 {
    margin-left: 33.3333%;
  }

  .offset-xlx-5 {
    margin-left: 41.6667%;
  }

  .offset-xlx-6 {
    margin-left: 50%;
  }

  .offset-xlx-7 {
    margin-left: 58.3333%;
  }

  .offset-xlx-8 {
    margin-left: 66.6667%;
  }

  .offset-xlx-9 {
    margin-left: 75%;
  }

  .offset-xlx-10 {
    margin-left: 83.3333%;
  }

  .offset-xlx-11 {
    margin-left: 91.6667%;
  }

  .g-xlx-0, .gx-xlx-0 {
    --bs-gutter-x: 0;
  }

  .g-xlx-0, .gy-xlx-0 {
    --bs-gutter-y: 0;
  }

  .g-xlx-1, .gx-xlx-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xlx-1, .gy-xlx-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xlx-2, .gx-xlx-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xlx-2, .gy-xlx-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xlx-3, .gx-xlx-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xlx-3, .gy-xlx-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xlx-4, .gx-xlx-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xlx-4, .gy-xlx-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xlx-5, .gx-xlx-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xlx-5, .gy-xlx-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1920px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, .05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, .1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, .075);
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cce3de;
  --bs-table-border-color: #b8ccc8;
  --bs-table-striped-bg: #c2d8d3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8ccc8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdd2cd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #ccdcdc;
  --bs-table-border-color: #b8c6c6;
  --bs-table-striped-bg: #c2d1d1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8c6c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdcccc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 389.98px) {
  .table-responsive-xxs {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 639.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1024.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1279.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-lxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1511.98px) {
  .table-responsive-xlx {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1919.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: #212529;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #949494;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #80b9ae;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(0, 114, 92, .25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  opacity: 1;
  background-color: #e1e1e1;
}

.form-control::-webkit-file-upload-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e1e1e1;
  border-radius: 0;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e1e1e1;
  border-radius: 0;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  border-right-width: 1px;
  margin-right: .75rem;
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  border-right-width: 1px;
  margin-right: .75rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: 1px;
  margin-left: .75rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: 1px;
  margin-left: .75rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #d6d6d6;
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #d6d6d6;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::-webkit-file-upload-button {
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: .5rem;
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: .5rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: .5rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::-webkit-file-upload-button {
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: 1rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: 1rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #949494;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #80b9ae;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(0, 114, 92, .25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: #e1e1e1;
}

.form-select:-moz-focusring {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 #212529;
}

.form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #80b9ae;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(0, 114, 92, .25);
}

.form-check-input:checked {
  background-color: #00725c;
  border-color: #00725c;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #00725c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #00725c;
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380b9ae'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(0, 114, 92, .25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(0, 114, 92, .25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #00725c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b3d5ce;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #c7c7c7;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #00725c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b3d5ce;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #c7c7c7;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.form-floating > label:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.form-floating > label:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.form-floating > label:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::-webkit-input-placeholder, .form-floating > .form-control-plaintext::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  -webkit-transform: scale(.85)translateY(-.5rem)translateX(.15rem);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e1e1e1;
  border: 1px solid #949494;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: rgba(25, 135, 84, .9);
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: rgba(220, 53, 69, .9);
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00725c;
  --bs-btn-border-color: #00725c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00614e;
  --bs-btn-hover-border-color: #005b4a;
  --bs-btn-focus-shadow-rgb: 38, 135, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005b4a;
  --bs-btn-active-border-color: #005645;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00725c;
  --bs-btn-disabled-border-color: #00725c;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #005252;
  --bs-btn-border-color: #005252;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #004646;
  --bs-btn-hover-border-color: #004242;
  --bs-btn-focus-shadow-rgb: 38, 108, 108;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004242;
  --bs-btn-active-border-color: #003e3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #005252;
  --bs-btn-disabled-border-color: #005252;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #00725c;
  --bs-btn-border-color: #00725c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00725c;
  --bs-btn-hover-border-color: #00725c;
  --bs-btn-focus-shadow-rgb: 0, 114, 92;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00725c;
  --bs-btn-active-border-color: #00725c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #00725c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00725c;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #005252;
  --bs-btn-border-color: #005252;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #005252;
  --bs-btn-hover-border-color: #005252;
  --bs-btn-focus-shadow-rgb: 0, 82, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005252;
  --bs-btn-active-border-color: #005252;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #005252;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #005252;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 38, 135, 116;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e1e1e1;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00725c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 390px) {
  .dropdown-menu-xxs-start {
    --bs-position: start;
  }

  .dropdown-menu-xxs-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxs-end {
    --bs-position: end;
  }

  .dropdown-menu-xxs-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 640px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1025px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1280px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-lxl-start {
    --bs-position: start;
  }

  .dropdown-menu-lxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lxl-end {
    --bs-position: end;
  }

  .dropdown-menu-lxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1512px) {
  .dropdown-menu-xlx-start {
    --bs-position: start;
  }

  .dropdown-menu-xlx-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xlx-end {
    --bs-position: end;
  }

  .dropdown-menu-xlx-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1920px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid rgba(0, 0, 0, 0);
  border-bottom: .3em solid rgba(0, 0, 0, 0);
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #c7c7c7;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #c7c7c7;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, .15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00725c;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .375rem;
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #c7c7c7;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e1e1e1 #e1e1e1 #c7c7c7;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #c7c7c7 #c7c7c7 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #00725c;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: rgba(0, 0, 0, .55);
  --bs-navbar-hover-color: rgba(0, 0, 0, .7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, .3);
  --bs-navbar-active-color: rgba(0, 0, 0, .9);
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, .9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, .9);
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, .1);
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-xs, .navbar > .container-xxs, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-lxl, .navbar > .container-xlx, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: rgba(0, 0, 0, 0);
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 390px) {
  .navbar-expand-xxs {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxs .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxs .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxs .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxs .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxs .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxs .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxs .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .navbar-expand-xxs .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxs .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 640px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1025px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1280px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-lxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-lxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .navbar-expand-lxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1512px) {
  .navbar-expand-xlx {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xlx .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xlx .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xlx .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xlx .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xlx .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xlx .navbar-toggler {
    display: none;
  }

  .navbar-expand-xlx .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .navbar-expand-xlx .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xlx .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1920px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  -webkit-transform: none !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, .55);
  --bs-navbar-hover-color: rgba(255, 255, 255, .75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, .25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, .1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, .03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 640px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: #004437;
  --bs-alert-bg: #cce3de;
  --bs-alert-border-color: #b3d5ce;
}

.alert-primary .alert-link {
  color: #00362c;
}

.alert-secondary {
  --bs-alert-color: #003131;
  --bs-alert-bg: #ccdcdc;
  --bs-alert-border-color: #b3cbcb;
}

.alert-secondary .alert-link {
  color: #002727;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out, transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 640px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 1025px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1280px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 389.98px) {
  .modal-fullscreen-xxs-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxs-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxs-down .modal-header, .modal-fullscreen-xxs-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxs-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 639.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1024.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1279.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-lxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lxl-down .modal-header, .modal-fullscreen-lxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lxl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1511.98px) {
  .modal-fullscreen-xlx-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xlx-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xlx-down .modal-header, .modal-fullscreen-xlx-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xlx-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1919.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(0, 114, 92, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(0, 82, 82, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #00725c !important;
}

.link-primary:hover, .link-primary:focus {
  color: #005b4a !important;
}

.link-secondary {
  color: #005252 !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #004242 !important;
}

.link-success {
  color: #198754 !important;
}

.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

@media (min-width: 390px) {
  .sticky-xxs-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xxs-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 640px) {
  .sticky-sm-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1025px) {
  .sticky-lg-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1280px) {
  .sticky-xl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-lxl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-lxl-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1512px) {
  .sticky-xlx-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xlx-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1920px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .176) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, .5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, .5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 0) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 390px) {
  .float-xxs-start {
    float: left !important;
  }

  .float-xxs-end {
    float: right !important;
  }

  .float-xxs-none {
    float: none !important;
  }

  .d-xxs-inline {
    display: inline !important;
  }

  .d-xxs-inline-block {
    display: inline-block !important;
  }

  .d-xxs-block {
    display: block !important;
  }

  .d-xxs-grid {
    display: grid !important;
  }

  .d-xxs-table {
    display: table !important;
  }

  .d-xxs-table-row {
    display: table-row !important;
  }

  .d-xxs-table-cell {
    display: table-cell !important;
  }

  .d-xxs-flex {
    display: flex !important;
  }

  .d-xxs-inline-flex {
    display: inline-flex !important;
  }

  .d-xxs-none {
    display: none !important;
  }

  .flex-xxs-fill {
    flex: auto !important;
  }

  .flex-xxs-row {
    flex-direction: row !important;
  }

  .flex-xxs-column {
    flex-direction: column !important;
  }

  .flex-xxs-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxs-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxs-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxs-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxs-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxs-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxs-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxs-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxs-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxs-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxs-center {
    justify-content: center !important;
  }

  .justify-content-xxs-between {
    justify-content: space-between !important;
  }

  .justify-content-xxs-around {
    justify-content: space-around !important;
  }

  .justify-content-xxs-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxs-start {
    align-items: flex-start !important;
  }

  .align-items-xxs-end {
    align-items: flex-end !important;
  }

  .align-items-xxs-center {
    align-items: center !important;
  }

  .align-items-xxs-baseline {
    align-items: baseline !important;
  }

  .align-items-xxs-stretch {
    align-items: stretch !important;
  }

  .align-content-xxs-start {
    align-content: flex-start !important;
  }

  .align-content-xxs-end {
    align-content: flex-end !important;
  }

  .align-content-xxs-center {
    align-content: center !important;
  }

  .align-content-xxs-between {
    align-content: space-between !important;
  }

  .align-content-xxs-around {
    align-content: space-around !important;
  }

  .align-content-xxs-stretch {
    align-content: stretch !important;
  }

  .align-self-xxs-auto {
    align-self: auto !important;
  }

  .align-self-xxs-start {
    align-self: flex-start !important;
  }

  .align-self-xxs-end {
    align-self: flex-end !important;
  }

  .align-self-xxs-center {
    align-self: center !important;
  }

  .align-self-xxs-baseline {
    align-self: baseline !important;
  }

  .align-self-xxs-stretch {
    align-self: stretch !important;
  }

  .order-xxs-first {
    order: -1 !important;
  }

  .order-xxs-0 {
    order: 0 !important;
  }

  .order-xxs-1 {
    order: 1 !important;
  }

  .order-xxs-2 {
    order: 2 !important;
  }

  .order-xxs-3 {
    order: 3 !important;
  }

  .order-xxs-4 {
    order: 4 !important;
  }

  .order-xxs-5 {
    order: 5 !important;
  }

  .order-xxs-last {
    order: 6 !important;
  }

  .m-xxs-0 {
    margin: 0 !important;
  }

  .m-xxs-1 {
    margin: .25rem !important;
  }

  .m-xxs-2 {
    margin: .5rem !important;
  }

  .m-xxs-3 {
    margin: 1rem !important;
  }

  .m-xxs-4 {
    margin: 1.5rem !important;
  }

  .m-xxs-5 {
    margin: 3rem !important;
  }

  .m-xxs-auto {
    margin: auto !important;
  }

  .mx-xxs-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxs-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxs-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxs-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxs-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxs-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxs-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxs-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxs-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxs-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxs-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxs-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxs-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxs-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxs-0 {
    margin-top: 0 !important;
  }

  .mt-xxs-1 {
    margin-top: .25rem !important;
  }

  .mt-xxs-2 {
    margin-top: .5rem !important;
  }

  .mt-xxs-3 {
    margin-top: 1rem !important;
  }

  .mt-xxs-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxs-5 {
    margin-top: 3rem !important;
  }

  .mt-xxs-auto {
    margin-top: auto !important;
  }

  .me-xxs-0 {
    margin-right: 0 !important;
  }

  .me-xxs-1 {
    margin-right: .25rem !important;
  }

  .me-xxs-2 {
    margin-right: .5rem !important;
  }

  .me-xxs-3 {
    margin-right: 1rem !important;
  }

  .me-xxs-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxs-5 {
    margin-right: 3rem !important;
  }

  .me-xxs-auto {
    margin-right: auto !important;
  }

  .mb-xxs-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxs-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxs-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxs-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxs-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxs-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxs-auto {
    margin-bottom: auto !important;
  }

  .ms-xxs-0 {
    margin-left: 0 !important;
  }

  .ms-xxs-1 {
    margin-left: .25rem !important;
  }

  .ms-xxs-2 {
    margin-left: .5rem !important;
  }

  .ms-xxs-3 {
    margin-left: 1rem !important;
  }

  .ms-xxs-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxs-5 {
    margin-left: 3rem !important;
  }

  .ms-xxs-auto {
    margin-left: auto !important;
  }

  .m-xxs-n1 {
    margin: -.25rem !important;
  }

  .m-xxs-n2 {
    margin: -.5rem !important;
  }

  .m-xxs-n3 {
    margin: -1rem !important;
  }

  .m-xxs-n4 {
    margin: -1.5rem !important;
  }

  .m-xxs-n5 {
    margin: -3rem !important;
  }

  .mx-xxs-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxs-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxs-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxs-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxs-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xxs-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxs-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxs-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxs-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxs-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxs-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxs-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxs-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxs-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxs-n5 {
    margin-top: -3rem !important;
  }

  .me-xxs-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxs-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxs-n3 {
    margin-right: -1rem !important;
  }

  .me-xxs-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxs-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxs-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxs-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxs-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxs-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxs-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxs-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxs-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxs-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxs-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxs-n5 {
    margin-left: -3rem !important;
  }

  .p-xxs-0 {
    padding: 0 !important;
  }

  .p-xxs-1 {
    padding: .25rem !important;
  }

  .p-xxs-2 {
    padding: .5rem !important;
  }

  .p-xxs-3 {
    padding: 1rem !important;
  }

  .p-xxs-4 {
    padding: 1.5rem !important;
  }

  .p-xxs-5 {
    padding: 3rem !important;
  }

  .px-xxs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxs-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxs-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxs-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxs-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxs-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxs-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxs-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxs-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxs-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxs-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxs-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxs-0 {
    padding-top: 0 !important;
  }

  .pt-xxs-1 {
    padding-top: .25rem !important;
  }

  .pt-xxs-2 {
    padding-top: .5rem !important;
  }

  .pt-xxs-3 {
    padding-top: 1rem !important;
  }

  .pt-xxs-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxs-5 {
    padding-top: 3rem !important;
  }

  .pe-xxs-0 {
    padding-right: 0 !important;
  }

  .pe-xxs-1 {
    padding-right: .25rem !important;
  }

  .pe-xxs-2 {
    padding-right: .5rem !important;
  }

  .pe-xxs-3 {
    padding-right: 1rem !important;
  }

  .pe-xxs-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxs-5 {
    padding-right: 3rem !important;
  }

  .pb-xxs-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxs-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxs-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxs-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxs-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxs-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxs-0 {
    padding-left: 0 !important;
  }

  .ps-xxs-1 {
    padding-left: .25rem !important;
  }

  .ps-xxs-2 {
    padding-left: .5rem !important;
  }

  .ps-xxs-3 {
    padding-left: 1rem !important;
  }

  .ps-xxs-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxs-5 {
    padding-left: 3rem !important;
  }

  .gap-xxs-0 {
    gap: 0 !important;
  }

  .gap-xxs-1 {
    gap: .25rem !important;
  }

  .gap-xxs-2 {
    gap: .5rem !important;
  }

  .gap-xxs-3 {
    gap: 1rem !important;
  }

  .gap-xxs-4 {
    gap: 1.5rem !important;
  }

  .gap-xxs-5 {
    gap: 3rem !important;
  }

  .fw-xxs-light {
    font-weight: 300 !important;
  }

  .fw-xxs-lighter {
    font-weight: lighter !important;
  }

  .fw-xxs-normal {
    font-weight: 400 !important;
  }

  .fw-xxs-bold {
    font-weight: 700 !important;
  }

  .fw-xxs-semibold {
    font-weight: 600 !important;
  }

  .fw-xxs-bolder {
    font-weight: bolder !important;
  }

  .text-xxs-start {
    text-align: left !important;
  }

  .text-xxs-end {
    text-align: right !important;
  }

  .text-xxs-center {
    text-align: center !important;
  }
}

@media (min-width: 640px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .fw-sm-light {
    font-weight: 300 !important;
  }

  .fw-sm-lighter {
    font-weight: lighter !important;
  }

  .fw-sm-normal {
    font-weight: 400 !important;
  }

  .fw-sm-bold {
    font-weight: 700 !important;
  }

  .fw-sm-semibold {
    font-weight: 600 !important;
  }

  .fw-sm-bolder {
    font-weight: bolder !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .fw-md-light {
    font-weight: 300 !important;
  }

  .fw-md-lighter {
    font-weight: lighter !important;
  }

  .fw-md-normal {
    font-weight: 400 !important;
  }

  .fw-md-bold {
    font-weight: 700 !important;
  }

  .fw-md-semibold {
    font-weight: 600 !important;
  }

  .fw-md-bolder {
    font-weight: bolder !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1025px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .fw-lg-light {
    font-weight: 300 !important;
  }

  .fw-lg-lighter {
    font-weight: lighter !important;
  }

  .fw-lg-normal {
    font-weight: 400 !important;
  }

  .fw-lg-bold {
    font-weight: 700 !important;
  }

  .fw-lg-semibold {
    font-weight: 600 !important;
  }

  .fw-lg-bolder {
    font-weight: bolder !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1280px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .fw-xl-light {
    font-weight: 300 !important;
  }

  .fw-xl-lighter {
    font-weight: lighter !important;
  }

  .fw-xl-normal {
    font-weight: 400 !important;
  }

  .fw-xl-bold {
    font-weight: 700 !important;
  }

  .fw-xl-semibold {
    font-weight: 600 !important;
  }

  .fw-xl-bolder {
    font-weight: bolder !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-lxl-start {
    float: left !important;
  }

  .float-lxl-end {
    float: right !important;
  }

  .float-lxl-none {
    float: none !important;
  }

  .d-lxl-inline {
    display: inline !important;
  }

  .d-lxl-inline-block {
    display: inline-block !important;
  }

  .d-lxl-block {
    display: block !important;
  }

  .d-lxl-grid {
    display: grid !important;
  }

  .d-lxl-table {
    display: table !important;
  }

  .d-lxl-table-row {
    display: table-row !important;
  }

  .d-lxl-table-cell {
    display: table-cell !important;
  }

  .d-lxl-flex {
    display: flex !important;
  }

  .d-lxl-inline-flex {
    display: inline-flex !important;
  }

  .d-lxl-none {
    display: none !important;
  }

  .flex-lxl-fill {
    flex: auto !important;
  }

  .flex-lxl-row {
    flex-direction: row !important;
  }

  .flex-lxl-column {
    flex-direction: column !important;
  }

  .flex-lxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-lxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-lxl-center {
    justify-content: center !important;
  }

  .justify-content-lxl-between {
    justify-content: space-between !important;
  }

  .justify-content-lxl-around {
    justify-content: space-around !important;
  }

  .justify-content-lxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lxl-start {
    align-items: flex-start !important;
  }

  .align-items-lxl-end {
    align-items: flex-end !important;
  }

  .align-items-lxl-center {
    align-items: center !important;
  }

  .align-items-lxl-baseline {
    align-items: baseline !important;
  }

  .align-items-lxl-stretch {
    align-items: stretch !important;
  }

  .align-content-lxl-start {
    align-content: flex-start !important;
  }

  .align-content-lxl-end {
    align-content: flex-end !important;
  }

  .align-content-lxl-center {
    align-content: center !important;
  }

  .align-content-lxl-between {
    align-content: space-between !important;
  }

  .align-content-lxl-around {
    align-content: space-around !important;
  }

  .align-content-lxl-stretch {
    align-content: stretch !important;
  }

  .align-self-lxl-auto {
    align-self: auto !important;
  }

  .align-self-lxl-start {
    align-self: flex-start !important;
  }

  .align-self-lxl-end {
    align-self: flex-end !important;
  }

  .align-self-lxl-center {
    align-self: center !important;
  }

  .align-self-lxl-baseline {
    align-self: baseline !important;
  }

  .align-self-lxl-stretch {
    align-self: stretch !important;
  }

  .order-lxl-first {
    order: -1 !important;
  }

  .order-lxl-0 {
    order: 0 !important;
  }

  .order-lxl-1 {
    order: 1 !important;
  }

  .order-lxl-2 {
    order: 2 !important;
  }

  .order-lxl-3 {
    order: 3 !important;
  }

  .order-lxl-4 {
    order: 4 !important;
  }

  .order-lxl-5 {
    order: 5 !important;
  }

  .order-lxl-last {
    order: 6 !important;
  }

  .m-lxl-0 {
    margin: 0 !important;
  }

  .m-lxl-1 {
    margin: .25rem !important;
  }

  .m-lxl-2 {
    margin: .5rem !important;
  }

  .m-lxl-3 {
    margin: 1rem !important;
  }

  .m-lxl-4 {
    margin: 1.5rem !important;
  }

  .m-lxl-5 {
    margin: 3rem !important;
  }

  .m-lxl-auto {
    margin: auto !important;
  }

  .mx-lxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lxl-0 {
    margin-top: 0 !important;
  }

  .mt-lxl-1 {
    margin-top: .25rem !important;
  }

  .mt-lxl-2 {
    margin-top: .5rem !important;
  }

  .mt-lxl-3 {
    margin-top: 1rem !important;
  }

  .mt-lxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lxl-5 {
    margin-top: 3rem !important;
  }

  .mt-lxl-auto {
    margin-top: auto !important;
  }

  .me-lxl-0 {
    margin-right: 0 !important;
  }

  .me-lxl-1 {
    margin-right: .25rem !important;
  }

  .me-lxl-2 {
    margin-right: .5rem !important;
  }

  .me-lxl-3 {
    margin-right: 1rem !important;
  }

  .me-lxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-lxl-5 {
    margin-right: 3rem !important;
  }

  .me-lxl-auto {
    margin-right: auto !important;
  }

  .mb-lxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-lxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lxl-auto {
    margin-bottom: auto !important;
  }

  .ms-lxl-0 {
    margin-left: 0 !important;
  }

  .ms-lxl-1 {
    margin-left: .25rem !important;
  }

  .ms-lxl-2 {
    margin-left: .5rem !important;
  }

  .ms-lxl-3 {
    margin-left: 1rem !important;
  }

  .ms-lxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lxl-5 {
    margin-left: 3rem !important;
  }

  .ms-lxl-auto {
    margin-left: auto !important;
  }

  .m-lxl-n1 {
    margin: -.25rem !important;
  }

  .m-lxl-n2 {
    margin: -.5rem !important;
  }

  .m-lxl-n3 {
    margin: -1rem !important;
  }

  .m-lxl-n4 {
    margin: -1.5rem !important;
  }

  .m-lxl-n5 {
    margin: -3rem !important;
  }

  .mx-lxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-lxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-lxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lxl-n5 {
    margin-top: -3rem !important;
  }

  .me-lxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-lxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-lxl-n3 {
    margin-right: -1rem !important;
  }

  .me-lxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-lxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-lxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lxl-n5 {
    margin-left: -3rem !important;
  }

  .p-lxl-0 {
    padding: 0 !important;
  }

  .p-lxl-1 {
    padding: .25rem !important;
  }

  .p-lxl-2 {
    padding: .5rem !important;
  }

  .p-lxl-3 {
    padding: 1rem !important;
  }

  .p-lxl-4 {
    padding: 1.5rem !important;
  }

  .p-lxl-5 {
    padding: 3rem !important;
  }

  .px-lxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lxl-0 {
    padding-top: 0 !important;
  }

  .pt-lxl-1 {
    padding-top: .25rem !important;
  }

  .pt-lxl-2 {
    padding-top: .5rem !important;
  }

  .pt-lxl-3 {
    padding-top: 1rem !important;
  }

  .pt-lxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lxl-5 {
    padding-top: 3rem !important;
  }

  .pe-lxl-0 {
    padding-right: 0 !important;
  }

  .pe-lxl-1 {
    padding-right: .25rem !important;
  }

  .pe-lxl-2 {
    padding-right: .5rem !important;
  }

  .pe-lxl-3 {
    padding-right: 1rem !important;
  }

  .pe-lxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lxl-5 {
    padding-right: 3rem !important;
  }

  .pb-lxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-lxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lxl-0 {
    padding-left: 0 !important;
  }

  .ps-lxl-1 {
    padding-left: .25rem !important;
  }

  .ps-lxl-2 {
    padding-left: .5rem !important;
  }

  .ps-lxl-3 {
    padding-left: 1rem !important;
  }

  .ps-lxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lxl-5 {
    padding-left: 3rem !important;
  }

  .gap-lxl-0 {
    gap: 0 !important;
  }

  .gap-lxl-1 {
    gap: .25rem !important;
  }

  .gap-lxl-2 {
    gap: .5rem !important;
  }

  .gap-lxl-3 {
    gap: 1rem !important;
  }

  .gap-lxl-4 {
    gap: 1.5rem !important;
  }

  .gap-lxl-5 {
    gap: 3rem !important;
  }

  .fw-lxl-light {
    font-weight: 300 !important;
  }

  .fw-lxl-lighter {
    font-weight: lighter !important;
  }

  .fw-lxl-normal {
    font-weight: 400 !important;
  }

  .fw-lxl-bold {
    font-weight: 700 !important;
  }

  .fw-lxl-semibold {
    font-weight: 600 !important;
  }

  .fw-lxl-bolder {
    font-weight: bolder !important;
  }

  .text-lxl-start {
    text-align: left !important;
  }

  .text-lxl-end {
    text-align: right !important;
  }

  .text-lxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1512px) {
  .float-xlx-start {
    float: left !important;
  }

  .float-xlx-end {
    float: right !important;
  }

  .float-xlx-none {
    float: none !important;
  }

  .d-xlx-inline {
    display: inline !important;
  }

  .d-xlx-inline-block {
    display: inline-block !important;
  }

  .d-xlx-block {
    display: block !important;
  }

  .d-xlx-grid {
    display: grid !important;
  }

  .d-xlx-table {
    display: table !important;
  }

  .d-xlx-table-row {
    display: table-row !important;
  }

  .d-xlx-table-cell {
    display: table-cell !important;
  }

  .d-xlx-flex {
    display: flex !important;
  }

  .d-xlx-inline-flex {
    display: inline-flex !important;
  }

  .d-xlx-none {
    display: none !important;
  }

  .flex-xlx-fill {
    flex: auto !important;
  }

  .flex-xlx-row {
    flex-direction: row !important;
  }

  .flex-xlx-column {
    flex-direction: column !important;
  }

  .flex-xlx-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xlx-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xlx-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xlx-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xlx-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xlx-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xlx-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xlx-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xlx-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xlx-start {
    justify-content: flex-start !important;
  }

  .justify-content-xlx-end {
    justify-content: flex-end !important;
  }

  .justify-content-xlx-center {
    justify-content: center !important;
  }

  .justify-content-xlx-between {
    justify-content: space-between !important;
  }

  .justify-content-xlx-around {
    justify-content: space-around !important;
  }

  .justify-content-xlx-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xlx-start {
    align-items: flex-start !important;
  }

  .align-items-xlx-end {
    align-items: flex-end !important;
  }

  .align-items-xlx-center {
    align-items: center !important;
  }

  .align-items-xlx-baseline {
    align-items: baseline !important;
  }

  .align-items-xlx-stretch {
    align-items: stretch !important;
  }

  .align-content-xlx-start {
    align-content: flex-start !important;
  }

  .align-content-xlx-end {
    align-content: flex-end !important;
  }

  .align-content-xlx-center {
    align-content: center !important;
  }

  .align-content-xlx-between {
    align-content: space-between !important;
  }

  .align-content-xlx-around {
    align-content: space-around !important;
  }

  .align-content-xlx-stretch {
    align-content: stretch !important;
  }

  .align-self-xlx-auto {
    align-self: auto !important;
  }

  .align-self-xlx-start {
    align-self: flex-start !important;
  }

  .align-self-xlx-end {
    align-self: flex-end !important;
  }

  .align-self-xlx-center {
    align-self: center !important;
  }

  .align-self-xlx-baseline {
    align-self: baseline !important;
  }

  .align-self-xlx-stretch {
    align-self: stretch !important;
  }

  .order-xlx-first {
    order: -1 !important;
  }

  .order-xlx-0 {
    order: 0 !important;
  }

  .order-xlx-1 {
    order: 1 !important;
  }

  .order-xlx-2 {
    order: 2 !important;
  }

  .order-xlx-3 {
    order: 3 !important;
  }

  .order-xlx-4 {
    order: 4 !important;
  }

  .order-xlx-5 {
    order: 5 !important;
  }

  .order-xlx-last {
    order: 6 !important;
  }

  .m-xlx-0 {
    margin: 0 !important;
  }

  .m-xlx-1 {
    margin: .25rem !important;
  }

  .m-xlx-2 {
    margin: .5rem !important;
  }

  .m-xlx-3 {
    margin: 1rem !important;
  }

  .m-xlx-4 {
    margin: 1.5rem !important;
  }

  .m-xlx-5 {
    margin: 3rem !important;
  }

  .m-xlx-auto {
    margin: auto !important;
  }

  .mx-xlx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xlx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xlx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xlx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xlx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xlx-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xlx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xlx-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xlx-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xlx-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xlx-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xlx-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xlx-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xlx-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xlx-0 {
    margin-top: 0 !important;
  }

  .mt-xlx-1 {
    margin-top: .25rem !important;
  }

  .mt-xlx-2 {
    margin-top: .5rem !important;
  }

  .mt-xlx-3 {
    margin-top: 1rem !important;
  }

  .mt-xlx-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xlx-5 {
    margin-top: 3rem !important;
  }

  .mt-xlx-auto {
    margin-top: auto !important;
  }

  .me-xlx-0 {
    margin-right: 0 !important;
  }

  .me-xlx-1 {
    margin-right: .25rem !important;
  }

  .me-xlx-2 {
    margin-right: .5rem !important;
  }

  .me-xlx-3 {
    margin-right: 1rem !important;
  }

  .me-xlx-4 {
    margin-right: 1.5rem !important;
  }

  .me-xlx-5 {
    margin-right: 3rem !important;
  }

  .me-xlx-auto {
    margin-right: auto !important;
  }

  .mb-xlx-0 {
    margin-bottom: 0 !important;
  }

  .mb-xlx-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xlx-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xlx-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xlx-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xlx-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xlx-auto {
    margin-bottom: auto !important;
  }

  .ms-xlx-0 {
    margin-left: 0 !important;
  }

  .ms-xlx-1 {
    margin-left: .25rem !important;
  }

  .ms-xlx-2 {
    margin-left: .5rem !important;
  }

  .ms-xlx-3 {
    margin-left: 1rem !important;
  }

  .ms-xlx-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xlx-5 {
    margin-left: 3rem !important;
  }

  .ms-xlx-auto {
    margin-left: auto !important;
  }

  .m-xlx-n1 {
    margin: -.25rem !important;
  }

  .m-xlx-n2 {
    margin: -.5rem !important;
  }

  .m-xlx-n3 {
    margin: -1rem !important;
  }

  .m-xlx-n4 {
    margin: -1.5rem !important;
  }

  .m-xlx-n5 {
    margin: -3rem !important;
  }

  .mx-xlx-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xlx-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xlx-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xlx-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xlx-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xlx-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xlx-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xlx-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xlx-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xlx-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xlx-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xlx-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xlx-n3 {
    margin-top: -1rem !important;
  }

  .mt-xlx-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xlx-n5 {
    margin-top: -3rem !important;
  }

  .me-xlx-n1 {
    margin-right: -.25rem !important;
  }

  .me-xlx-n2 {
    margin-right: -.5rem !important;
  }

  .me-xlx-n3 {
    margin-right: -1rem !important;
  }

  .me-xlx-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xlx-n5 {
    margin-right: -3rem !important;
  }

  .mb-xlx-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xlx-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xlx-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xlx-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xlx-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xlx-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xlx-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xlx-n3 {
    margin-left: -1rem !important;
  }

  .ms-xlx-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xlx-n5 {
    margin-left: -3rem !important;
  }

  .p-xlx-0 {
    padding: 0 !important;
  }

  .p-xlx-1 {
    padding: .25rem !important;
  }

  .p-xlx-2 {
    padding: .5rem !important;
  }

  .p-xlx-3 {
    padding: 1rem !important;
  }

  .p-xlx-4 {
    padding: 1.5rem !important;
  }

  .p-xlx-5 {
    padding: 3rem !important;
  }

  .px-xlx-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xlx-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xlx-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xlx-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xlx-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xlx-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xlx-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xlx-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xlx-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xlx-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xlx-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xlx-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xlx-0 {
    padding-top: 0 !important;
  }

  .pt-xlx-1 {
    padding-top: .25rem !important;
  }

  .pt-xlx-2 {
    padding-top: .5rem !important;
  }

  .pt-xlx-3 {
    padding-top: 1rem !important;
  }

  .pt-xlx-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xlx-5 {
    padding-top: 3rem !important;
  }

  .pe-xlx-0 {
    padding-right: 0 !important;
  }

  .pe-xlx-1 {
    padding-right: .25rem !important;
  }

  .pe-xlx-2 {
    padding-right: .5rem !important;
  }

  .pe-xlx-3 {
    padding-right: 1rem !important;
  }

  .pe-xlx-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xlx-5 {
    padding-right: 3rem !important;
  }

  .pb-xlx-0 {
    padding-bottom: 0 !important;
  }

  .pb-xlx-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xlx-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xlx-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xlx-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xlx-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xlx-0 {
    padding-left: 0 !important;
  }

  .ps-xlx-1 {
    padding-left: .25rem !important;
  }

  .ps-xlx-2 {
    padding-left: .5rem !important;
  }

  .ps-xlx-3 {
    padding-left: 1rem !important;
  }

  .ps-xlx-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xlx-5 {
    padding-left: 3rem !important;
  }

  .gap-xlx-0 {
    gap: 0 !important;
  }

  .gap-xlx-1 {
    gap: .25rem !important;
  }

  .gap-xlx-2 {
    gap: .5rem !important;
  }

  .gap-xlx-3 {
    gap: 1rem !important;
  }

  .gap-xlx-4 {
    gap: 1.5rem !important;
  }

  .gap-xlx-5 {
    gap: 3rem !important;
  }

  .fw-xlx-light {
    font-weight: 300 !important;
  }

  .fw-xlx-lighter {
    font-weight: lighter !important;
  }

  .fw-xlx-normal {
    font-weight: 400 !important;
  }

  .fw-xlx-bold {
    font-weight: 700 !important;
  }

  .fw-xlx-semibold {
    font-weight: 600 !important;
  }

  .fw-xlx-bolder {
    font-weight: bolder !important;
  }

  .text-xlx-start {
    text-align: left !important;
  }

  .text-xlx-end {
    text-align: right !important;
  }

  .text-xlx-center {
    text-align: center !important;
  }
}

@media (min-width: 1920px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .fw-xxl-light {
    font-weight: 300 !important;
  }

  .fw-xxl-lighter {
    font-weight: lighter !important;
  }

  .fw-xxl-normal {
    font-weight: 400 !important;
  }

  .fw-xxl-bold {
    font-weight: 700 !important;
  }

  .fw-xxl-semibold {
    font-weight: 600 !important;
  }

  .fw-xxl-bolder {
    font-weight: bolder !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  -webkit-animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: rgba(0, 0, 0, 0);
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.popover:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.iti {
  display: inline-block;
  position: relative;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input, .iti input[type="text"], .iti input[type="tel"] {
  z-index: 0;
  margin-right: 0;
  padding-right: 36px;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.iti__flag-container {
  padding: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.iti__selected-flag {
  z-index: 1;
  height: 100%;
  align-items: center;
  padding: 0 6px 0 8px;
  display: flex;
  position: relative;
}

.iti__arrow {
  width: 0;
  height: 0;
  border-top: 4px solid #555;
  border-left: 3px solid rgba(0, 0, 0, 0);
  border-right: 3px solid rgba(0, 0, 0, 0);
  margin-left: 6px;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  z-index: 2;
  text-align: left;
  white-space: nowrap;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 0 0 0 -1px;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-y: scroll;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
}

.iti__country-list--dropup {
  margin-bottom: -1px;
  bottom: 100%;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}

.iti__flag-box {
  width: 20px;
  display: inline-block;
}

.iti__divider {
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.iti__country {
  outline: none;
  padding: 5px 10px;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, .05);
}

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type="text"], .iti--allow-dropdown input[type="tel"], .iti--separate-dial-code input, .iti--separate-dial-code input[type="text"], .iti--separate-dial-code input[type="tel"] {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  left: 0;
  right: auto;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, .05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0);
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, .05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  z-index: 1060;
  padding: 1px;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  position: fixed;
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0 0;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0;
}

.iti__flag {
  height: 15px;
  background-color: #dbdbdb;
  background-image: url("../flags.c61229ae.png");
  background-position: 20px 0;
  background-repeat: no-repeat;
  box-shadow: 0 0 1px #888;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../flags@2x.adefcad5.png");
  }
}

.iti__flag.iti__np {
  background-color: rgba(0, 0, 0, 0);
}

.pop-up-validation {
  color: #dbdbdb;
  z-index: 1000;
  cursor: pointer;
  background-color: #222;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: .8rem;
  display: none;
  position: absolute;
  box-shadow: 0 0 3px 1px #818181;
}

.pop-up-validation p {
  max-width: 360px;
  margin: 0;
}

.pop-up-validation .pointer {
  width: 0;
  height: 0;
  border-bottom: 10px solid #222;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: -10px;
  left: calc(50% - 10px);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media screen and (max-width: 580px) {
  .pop-up-validation p {
    max-width: 250px;
    margin: 0;
  }
}

:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
  --navbar-height: 80px;
  --color-background: #060f24;
  --color-btn: #8acbb7;
  --color-btn-hover: #7dfed7;
}

body {
  min-height: 100vh;
  background: var(--color-background);
  color: #fff;
  background-size: cover;
  margin: 0;
  font-style: normal;
  position: relative;
  font-family: Montserrat, Arial, sans-serif !important;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

#background-container {
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--color-background);
  position: absolute;
  overflow: hidden;
}

#background-container .img-background {
  width: 660px;
  height: 990px;
}

#base-background {
  mix-blend-mode: lighten;
  background: url("https://resources.ocumetra.com/images/backgrounds/base.webp") 0% no-repeat;
  position: absolute;
  right: 0;
  background-size: 1100px !important;
}

#all-content {
  width: 100%;
  min-height: calc(100vh - var(--navbar-height));
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;
  display: flex;
}

#print-help-results {
  display: none;
}

.meye-logo-title {
  color: #fff;
  margin: 10px 0;
  font-family: Bradley Hand, sans-serif;
  font-size: 50px;
}

.meye-logo-title span {
  font-family: Neue Haas Unica, sans-serif;
  font-weight: 500;
}

.welcome-container, .error-container {
  width: 100%;
  margin: 0 100px;
}

.welcome-container h1, .welcome-container .h1, .error-container h1, .error-container .h1 {
  font-size: 80px;
  font-weight: 300;
}

.error-container h2, .error-container .h2 {
  font-size: 40px;
  font-weight: 300;
}

.connect-button-container {
  width: 450px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.form-btn-container {
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  display: flex;
}

.form-btn {
  color: var(--color-background);
  border: 2px solid var(--color-btn);
  background-color: var(--color-btn);
  cursor: pointer;
  border-radius: 7px;
  padding: 10px 40px;
  font-size: 22px;
  font-weight: 300;
  text-decoration: none;
  transition: all .2s;
}

.form-btn:hover {
  color: var(--color-background);
  background-color: var(--color-btn-hover);
  text-decoration: none;
}

.form-btn:disabled {
  opacity: .8;
  border-color: var(--color-background);
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}

#nav {
  min-height: var(--navbar-height);
  padding: 10px 60px;
  font-weight: 500;
}

#logo-navbar {
  content: url("../ocumetra_logo_light.1753a726.svg");
  width: 160px;
  background-repeat: no-repeat;
  display: block;
}

li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li.li-margin {
  margin-left: 1.5rem;
}

li.bullet {
  list-style-type: disc;
}

table {
  border-spacing: 10px;
  border-collapse: separate;
  width: 80%;
}

.navbar-dark .navbar-toggler {
  border-color: #fff;
}

.navbar-toggler .line {
  width: 22px;
  height: 2px;
  background-color: #fff;
  border-radius: 10px;
  margin: 6px;
  transition: all .5s;
}

.active #line1 {
  -webkit-transform: rotate(-45deg)translate(-6px, 6px);
  transform: rotate(-45deg)translate(-6px, 6px);
}

.active #line2 {
  opacity: 0;
}

.active #line3 {
  -webkit-transform: rotate(45deg)translate(-5px, -6px);
  transform: rotate(45deg)translate(-5px, -6px);
}

.profile {
  width: 100%;
}

.card-profile {
  width: 600px;
  min-width: 450px;
  color: #28283d;
  border: 0;
  border-radius: 1rem;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
}

.blue-eye-color {
  color: #171792;
}

.card-base {
  width: 50%;
  min-width: 300px;
  max-width: 500px;
  color: #28283d;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, .2);
  border-radius: 1rem;
  padding: 2rem;
}

.card-base .card-title {
  text-align: center;
  color: #fff;
  margin-bottom: 2rem;
  font-size: 40px;
  font-weight: bold;
}

.card-register {
  max-width: 900px;
}

.form-base {
  width: 100%;
}

.form-group-row {
  grid-gap: 0 10px;
  justify-content: space-between;
  display: flex;
}

.form-group-row > div {
  width: 100%;
}

.form-label-group {
  margin-bottom: 1rem;
  position: relative;
}

.form-label-group select {
  -webkit-appearance: none;
  appearance: none;
}

.form-label-group input, .form-label-group select {
  height: 48px;
  background-color: #fff;
  border: none;
  border-radius: 14px;
}

.dark input:-internal-autofill-selected {
  -webkit-text-fill-color: white;
  background-color: #001923 !important;
  -webkit-box-shadow: inset 0 0 0 1000px #001923 !important;
}

.dark input, .dark select {
  color-scheme: dark;
}

.form-label-group > input, .form-label-group > label, .form-label-group > select {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  color: #495057;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .25rem;
  margin-bottom: 0;
  line-height: 1.5;
  transition: all .1s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.show-hide-password-btn {
  z-index: 100;
  width: 30px;
  height: 25px;
  object-fit: contain;
  cursor: pointer;
  align-self: center;
  display: inline-block;
  position: relative;
}

.show-hide-password-btn.show {
  background: url("https://resources.ocumetra.com/images/icons/eye-hide-icon.svg") 50% no-repeat;
}

.show-hide-password-btn.hide {
  background: url("https://resources.ocumetra.com/images/icons/eye-show-icon.svg") 50% no-repeat;
}

.formgroup input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.formgroup input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-label-group input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-label-group input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-label-group input:not(:placeholder-shown), .form-label-group select {
  width: 100%;
  padding-top: calc(var(--input-padding-y)  + var(--input-padding-y) * .666667);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 18px;
}

.form-label-group label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  color: #28283d;
  font-size: 12px;
}

.card-link-container {
  color: #fff;
  justify-content: space-between;
  gap: 0 10px;
  display: flex;
}

.card-link-container .checkbox-control {
  align-items: baseline;
  gap: 4px;
  display: flex;
}

.card-link-container a, .card-link-container a:hover {
  color: #fff;
  text-decoration: none;
}

.card-link-container .custom-control-label a {
  color: #8acbb7;
}

.custom-control-label {
  cursor: pointer;
}

.iti {
  width: 100%;
}

.iti__selected-flag {
  padding: 0;
}

.iti--allow-dropdown input[type="tel"] {
  padding: 0;
}

.iti__country-list {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
}

.nav-link.active {
  color: #7dfed7 !important;
}

.navbar .form-select-sm {
  color: #a3a3a3;
  align-items: center;
  padding-left: 0;
  display: flex;
  position: relative;
}

.navbar .form-select-sm > select, .navbar .form-select-sm ul {
  cursor: pointer;
  color: inherit;
  text-align: right;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: 1rem;
  font-weight: 500;
}

.navbar .form-select-sm > select:hover, .navbar .form-select-sm ul:hover {
  color: #e5e5e5;
}

.navbar .form-select-sm:after {
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: block;
  position: relative;
  left: -16px;
}

.dropdown-btn {
  color: rgba(255, 255, 255, .55);
  font-weight: 500;
}

.dropdown-menu {
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  color: #fff !important;
  background-color: rgba(255, 255, 255, .2) !important;
  border: 0 !important;
}

.dropdown-menu a:link, .dropdown-menu a:visited, .dropdown-menu a:active {
  color: #fff;
}

a {
  text-decoration: none;
}

.dropdown-menu a:hover, .dropdown-menu a:focus {
  color: #272727;
  text-decoration: none;
}

.dropdown-item {
  color: #fff;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--color-background) !important;
  color: #fff !important;
}

.links-container {
  margin-left: 50px;
  display: flex;
}

.links-container ul {
  margin: 0 80px 0 0;
  padding: 0;
}

.links-container a {
  color: #fff;
}

.links-container a:hover {
  color: #d0d2d3;
  text-decoration: none;
}

.links-container span, .source-container a:link, .source-container a:visited {
  color: #8acbb7;
}

.copyright-container {
  text-align: right;
  flex-direction: column;
  margin-right: 50px;
  display: flex;
}

.modal-title {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-background);
  font-weight: bold;
  text-decoration: underline;
}

.header-style {
  font-size: calc(1.375rem + .8vw);
  font-weight: 500;
}

.modal-body {
  color: var(--color-background);
}

.terms-title {
  text-align: justify;
  font-family: Segoe UI Semibold, sans-serif;
  font-size: 24px;
}

.modal-body li {
  list-style-type: disc;
}

.modal-body .decimal li {
  list-style-type: decimal;
}

.modal-body ol li {
  list-style-type: lower-alpha;
}

.modal-body h2, .modal-body .h2 {
  text-align: center;
  margin-bottom: 1rem;
  font-family: Segoe UI, sans-serif;
  font-size: 24px;
  font-weight: normal;
  text-decoration: underline;
}

.modal-body h3, .modal-body .h3 {
  margin-bottom: 1rem;
  font-size: 20px;
}

.modal-body h4, .modal-body .h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.modal-text-body {
  text-align: justify;
  line-height: 115%;
}

.subscription-success {
  width: 100%;
}

.card-success-container {
  width: 85%;
  max-width: 1200px;
  color: #28283d;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, .2);
  border-radius: 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: auto;
  padding: 2rem;
  display: flex;
}

.img-doctor-success {
  content: url("https://resources.ocumetra.com/images/pictures/doctor-succes.png");
  width: 12em;
  background-repeat: no-repeat;
  display: block;
}

.text-success-container {
  color: #fff;
  width: 40%;
}

.text-success-container h2, .text-success-container .h2 {
  margin-bottom: 20px;
}

.text-success-container p {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
}

.text-success-container a {
  color: var(--color-btn);
  font-weight: 500;
}

.floating-label {
  color: #060f24;
  background-color: #8acbb7;
  margin-top: -20px;
  margin-right: 10px;
  line-height: 16px;
}

.brand-container {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.brand-container .brand-item.source-item {
  text-align: center;
  order: 2;
}

.brand-container .brand-item.logo-item:nth-of-type(2) {
  order: 1;
}

.brand-container .brand-item.logo-item:nth-of-type(3) {
  order: 3;
}

.focus-alert {
  border: none;
  outline: none;
}

.coopervision-logo-macro p {
  text-align: right;
  color: var(--color-btn);
  margin-bottom: 0;
}

.coopervision-logo-macro img {
  max-width: 183px;
  height: auto;
}

.coopervision-logo-break-margin {
  margin-top: 30px;
}

.ocumetra-logo-macro {
  max-width: 246px;
}

.ocumetra-logo-macro p {
  color: #fff;
  margin-bottom: 0;
  margin-left: 70px;
  font-size: 12px;
}

.ocumetra-logo-macro img {
  width: 100%;
  height: auto;
}

.ad-card-control {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #d3d3d3;
}

.ad-card-control .ad-list {
  height: 100%;
  max-width: 350px;
  margin-bottom: 0;
  padding: 0;
}

.ad-card-control .ad-fill {
  border-radius: inherit;
  flex: 1;
  padding: 12px 120px 12px 12px;
  font-size: 12px;
}

.ad-card-control .ad-aside {
  color: #000;
  flex: 1;
  padding: 12px;
  font-size: 12px;
}

.ad-card-control .ad-fill-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.ad-card-control .ad-button-link {
  border: none;
  border-radius: 10px;
  padding: 8px 10px;
  transition: all .2s;
}

.ad-card-control .ad-item-container {
  align-items: baseline;
  gap: 5px;
  display: flex;
}

.ad-card-control .ad-item-container img {
  display: block;
}

.ad-float-container {
  float: right;
  margin: 0 0 16px 16px;
}

.ad-float-container img {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ad-footnote {
  color: #8d949c;
  font-size: 12px;
}

.superscript {
  vertical-align: super;
  font-size: .6rem;
}

#dashboard-container {
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  background-color: #0c1219;
  align-items: center;
  display: flex;
  box-shadow: 0 10px 20px 20px #0c1219;
}

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }

  .formgroup input::-webkit-input-placeholder {
    color: #28283d;
  }

  .formgroup input::placeholder {
    color: #28283d;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }

  .formgroup input::-webkit-input-placeholder {
    color: #28283d;
  }

  .formgroup input::placeholder {
    color: #28283d;
  }
}

@media screen and (max-width: 991px) {
  .small-letter-text {
    margin-bottom: -20px;
  }

  .text-span {
    font-size: 17px !important;
  }

  .blue-data-table .data-table-header {
    font-size: 18px !important;
  }

  .blue-data-table .data-table-content {
    padding: 15px 10px !important;
    font-size: 18px !important;
  }

  .blue-data-table .data-table-content .big-metric-value {
    font-size: 27px;
  }

  .form-group-row {
    flex-wrap: wrap;
  }

  #nav {
    padding: 10px 20px;
  }

  .links-container {
    width: 100%;
    justify-content: space-around;
    margin: 0 0 20px;
  }

  .links-container ul {
    margin: 0;
  }

  .copyright-container {
    text-align: center;
    margin: 0;
  }

  .subscription-container {
    width: 100%;
    padding: 20px;
  }

  .card-link-container {
    flex-direction: column;
  }

  .card-success-container {
    width: 90%;
    flex-direction: column;
  }

  .text-success-container {
    width: 100%;
  }

  .floating-label {
    max-width: 100px;
    margin-right: 15px;
    font-size: 14px;
  }

  .brand-container .brand-item.source-item {
    width: 100%;
    flex: auto;
    order: initial;
    margin-bottom: 30px;
  }

  .coopervision-logo-macro {
    max-width: 153px;
  }

  .coopervision-logo-macro img {
    width: 100%;
    height: auto;
  }

  .coopervision-logo-break-margin {
    margin-top: 0;
    margin-right: -30px;
  }

  .ocumetra-logo-macro {
    max-width: 160px;
  }

  .ocumetra-logo-macro p {
    margin-left: 45px;
  }
}

@media screen and (max-width: 580px) {
  .ad-card-control .ad-fill {
    padding: 12px !important;
  }

  .card-base {
    width: 90%;
  }

  .card-manage {
    margin: 0;
  }

  .floating-label {
    max-width: 80px;
    margin-top: -20px;
    padding-top: 35px;
  }

  .coopervision-logo-macro img {
    max-width: 100px;
  }

  .coopervision-logo-macro p {
    font-size: 8px;
  }

  .coopervision-logo-break-margin {
    margin-right: 0;
  }

  .blue-data-table .data-table-header {
    font-size: 16px !important;
  }

  .blue-data-table .data-table-content {
    font-size: 15px !important;
  }

  .blue-data-table .data-table-content .flex-row {
    flex-direction: column !important;
  }

  .blue-data-table .data-table-content .big-metric-value {
    padding-bottom: 10px;
  }
}

#waiting-div {
  z-index: 50;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, .8);
  border-radius: 20px;
  padding: 4rem;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .35);
}

.p-absolute {
  position: absolute !important;
}

.mt-n-75 {
  margin-top: -75px;
}

.mt-n-15 {
  margin-top: -15px;
}

.justify-content-space-between {
  justify-content: space-between;
}

.post-treatment {
  justify-content: center;
}

.post-treatment .blue-data-table {
  flex: none;
  margin-top: 10px;
  margin-bottom: 10px !important;
}

.post-treatment .blue-data-table .data-table-content {
  padding: 25px 20px;
}

@media screen and (min-width: 1025px) {
  .post-treatment .blue-data-table .data-table-content.dense {
    padding: 10px 17.5px 15px;
  }
}

.current-progression.blue-data-table .data-table-content {
  padding: 25px 10px 35px;
}

.blue-data-table.treatment-benefit .data-table-content {
  padding: 10px;
}

.blue-data-table.treatment-benefit .data-table-content .row .col.predicted-text {
  padding: 0 20px;
}

@media screen and (min-width: 390px) {
  .blue-data-table.treatment-benefit .data-table-content .row .col.predicted-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 1512px) {
  .blue-data-table.treatment-benefit .data-table-content .row .col.predicted-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 390px) {
  .blue-data-table.treatment-benefit .data-table-content .row .col-auto.metric-value {
    font-size: 20px;
  }
}

@media screen and (min-width: 1512px) {
  .blue-data-table.treatment-benefit .data-table-content .row .col-auto.metric-value {
    font-size: 27px;
  }
}

.small-letter-text {
  margin-bottom: -25px;
  font-size: 9px;
}

.blue-data-table {
  width: 100%;
  text-align: center;
  flex-direction: column;
  margin-bottom: 1.7rem;
  display: flex;
}

.blue-data-table .data-table-header {
  width: 100%;
  color: #fff;
  background-color: #3a3af2;
  border-radius: 15px 15px 0 0;
  padding: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
}

.blue-data-table .data-table-header span {
  margin-left: 5px;
}

.blue-data-table .data-table-header span .tooltip {
  display: block;
}

.blue-data-table .data-table-content {
  width: 100%;
  border: 2px solid #e6e6ff;
  border-top-width: 0;
  border-radius: 0 0 15px 15px;
  padding: 30px 10px;
  font-size: 20px;
  line-height: 25px;
}

.metric-value {
  font-size: 27px;
}

.big-metric-value {
  font-size: 34px;
}

.metric-value-limiter {
  max-width: 220px;
  line-height: 120%;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.chart-frame {
  border: 2px solid #e6e6ff;
  border-radius: 10px;
  padding: 35px 30px;
}

.chart-frame .chart-title {
  color: #3a3af2;
  padding: 5px;
  font-size: 24px;
  font-weight: 600;
}

@media screen and (min-width: 0) {
  .chart-frame .chart-title {
    font-size: 14px;
  }
}

@media screen and (min-width: 640px) {
  .chart-frame .chart-title {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .chart-frame .chart-title {
    font-size: 24px;
  }
}

.chart-frame .chart-subtitle {
  font-size: 18px;
  font-weight: 600;
}

@media screen and (min-width: 0) {
  .chart-frame .chart-subtitle {
    font-size: 10px;
  }
}

@media screen and (min-width: 640px) {
  .chart-frame .chart-subtitle {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .chart-frame .chart-subtitle {
    font-size: 18px;
  }
}

.chart-frame svg {
  width: 100%;
  height: 100%;
}

.tab-content .override-collapse.tab-pane {
  opacity: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 2s, transform 2s, opacity 2s;
  display: grid;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.tab-content .override-collapse.tab-pane.show {
  opacity: 1;
  transition: -webkit-transform 2s, transform 2s, opacity 2s;
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.grid-template {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  display: grid;
}

.grid-column-1-2 {
  grid-column: 1 / 2;
}

.grid-row-1-2 {
  grid-row: 1 / 2;
}

.hover-card-container:first-child {
  padding-left: 0;
}

.hover-card-container:last-child {
  padding-right: 0;
}

/*# sourceMappingURL=base.css.map */
